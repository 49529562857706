import React, { useEffect, useState } from 'react';
import './FaqModalBox.css';
import { useTriggers } from '../../hooks/navigation';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { useDebounce } from '../../hooks/useDebounce';
import { useTranslation } from 'react-i18next';

const FaqModalBox = () => {
  const { t } = useTranslation();
  const { handleFaqMenuCloseClick } = useTriggers();
  const [loading, setLoading] = useState(false);
  const [faqContent, setFaqContent] = useState([]);
  const darkMode = useSelector((state) => state.navigation.darkMode);
  const [focusedFaqBlock, setFocusedFaqBlock] = useState(null);
  const handleFetchFaqContent = () => {
    const url = `${new URL(window.location.href).origin}/FAQ.md`;
    setLoading(true);
    fetch(url)
      .then((resp) => {
        resp.text().then((content) => {
          const parsedMarkdown = parseMarkdown(content);
          setFaqContent(parsedMarkdown);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        });
      })
      .catch(() => {
        //
        setLoading(false);
      });
  };

  const parseMarkdown = (markdown) => {
    const lines = markdown.split('\n');
    const sections = [];
    let currentSection = null;

    lines.forEach((line) => {
      if (line.startsWith('## ')) {
        // Start a new section
        currentSection = { title: line.substring(3), items: [] };
        sections.push(currentSection);
      } else if (line.startsWith('### ') && currentSection) {
        // Add a new item in the current section
        currentSection.items.push({ title: line.substring(4), content: '' });
      } else if (currentSection && currentSection.items.length > 0) {
        // Add content to the last item in the current section
        currentSection.items[currentSection.items.length - 1].content +=
          line + '\n';
      }
    });

    return sections;
  };

  useEffect(() => {
    handleFetchFaqContent();
  }, []);

  return (
    <div className="modal faq-modal">
      <div className="w-layout-vflex modal-container help-modal-container help-container">
        <div className="w-layout-hflex modal-header help-modal-header">
          <h3 className="modal-header-title">{t('FAQ')}</h3>
          <div
            prop-events-value-onclick="handleModalCloseClick"
            prop-events-names="onClick"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            className="modal-close-wrapper"
            onClick={handleFaqMenuCloseClick}
          >
            <img
              src="/images/close_1close.png"
              loading="lazy"
              alt="Close Button Image"
            />
          </div>
        </div>
        <div className="w-layout-vflex modal-body help-modal-body">
          {loading && (
            <IBLSpinLoader
              size={30}
              color={!darkMode ? 'var(--primary)' : '#FFF'}
              containerHeight={'200px'}
            />
          )}
          {!loading &&
            faqContent?.map((faq, index) => (
              <FAQSectionBlock
                key={`faq-section-${index}`}
                index={index}
                section={faq}
                focused={focusedFaqBlock}
                setFocused={setFocusedFaqBlock}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const FAQSectionBlock = ({ section, index, focused, setFocused }) => {
  const darkMode = useSelector((state) => state.navigation.darkMode);
  return (
    <>
      <div className="w-layout-vflex flex-block-13">
        <div>{section?.title}</div>
      </div>
      {section?.items?.map((subsection, subIndex) => (
        <div
          key={`faq-section-${index}-subsection-${subIndex}`}
          data-collapsed={focused === `${index}-${subIndex}` ? 'false' : 'true'}
          className={`w-layout-vflex single-faq-block ${focused === `${index}-${subIndex}` ? 'active' : ''}`}
          onClick={() =>
            setFocused(
              focused === `${index}-${subIndex}` ? null : `${index}-${subIndex}`
            )
          }
        >
          <div className="w-layout-hflex help-element faq-element">
            <div className="w-layout-hflex flex-block-100">
              <div className="help-el-icon-container">
                <img
                  src="/images/question-answer-svgrepo-com-1.svg"
                  loading="lazy"
                  alt=""
                  className="help-el-icon-img"
                />
              </div>
              <div className="help-el-description">{subsection?.title}</div>
            </div>
            <img
              src="/images/chevron-down.svg"
              loading="lazy"
              alt=""
              className="single-faq-block-chevron down whiten-in-dark-mode"
            />
            <img
              src="/images/chevron-up.svg"
              loading="lazy"
              alt=""
              className="single-faq-block-chevron up whiten-in-dark-mode"
            />
          </div>
          <div
            className="w-layout-hflex single-faq-content-block"
            style={{
              display: focused === `${index}-${subIndex}` ? 'flex' : 'none',
            }}
          >
            <p className="single-faq-content-block-parag">
              <MarkdownPreview
                source={subsection?.content}
                style={{ padding: 16 }}
                wrapperElement={{
                  'data-color-mode': darkMode ? 'dark' : 'light',
                }}
                disableCopy={false}
              />
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default FaqModalBox;
