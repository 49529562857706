import PageLoader from '../loader/page-loader';
import { IndexView } from '../../views';
import usePlatformRequest from '../../hooks/platform/usePlatformRequest';
import { Suspense, useEffect } from 'react';

export default function Platform() {
  const { ready } = usePlatformRequest();

  return (
    <Suspense fallback={<PageLoader />}>
      {ready ? <IndexView /> : <PageLoader />}
    </Suspense>
  );
}
