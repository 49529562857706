import React from 'react';
import './Datasetlistmodalbox.css';
import { useTranslation } from 'react-i18next';

const Datasetlistmodalbox = (props) => {
  const { t } = useTranslation();
  return (
    <div className="modal dataset-tabled-list-modal">
      <div className="w-layout-vflex modal-container prompt-gallery-container edit-mentor-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{t('Datasets')}</h3>
          <a className="default-btn prompt-add-btn w-button" href="#">
            {t('+ Add New Prompt')}
          </a>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body all-whiten-in-dark-mode">
          <div className="w-layout-vflex flex-block-71">
            <div className="text-block-25">{t('Showing list of datasets')}</div>
            <div className="w-layout-hflex table-top-header-block">
              <div className="w-layout-hflex search-box-block all-whiten-in-dark-mode">
                <img
                  alt=""
                  className="image-49"
                  loading="lazy"
                  src="/images/search-1.svg"
                />
                <div className="search-box-form-block w-form">
                  <form
                    data-name="Email Form 4"
                    data-wf-element-id="b41c107a-f3ed-b11e-8b4c-9bc5ce420e37"
                    data-wf-page-id="650305782bc61751e5765214"
                    id="email-form-4"
                    method="get"
                    name="email-form-4"
                  >
                    <input
                      className="text-field-4 w-input"
                      data-name="Name 5"
                      id="name-5"
                      maxLength={256}
                      name="name-5"
                      placeholder="Search datasets"
                      type="text"
                    />
                  </form>
                  <div className="w-form-done">
                    <div>
                      {t('Thank you! Your submission has been received!')}
                    </div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      {t(
                        'Oops! Something went wrong while submitting the form.'
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="button-with-icon users-settings-invite-btn"
                data-w-id="b41c107a-f3ed-b11e-8b4c-9bc5ce420e3f"
              >
                <img
                  alt=""
                  className="button-icon hidden"
                  loading="lazy"
                  src="/images/user-black-thick.svg"
                  width={70}
                />
                <div className="button-text">{t('Add Resource')}</div>
              </div>
            </div>
            <div className="w-layout-vflex table-container">
              <div className="w-layout-hflex table-header">
                <div className="table-block table-block-very-big all-whiten-in-dark-mode">
                  <h5 className="table-title">{t('NAME')}</h5>
                  <img
                    alt=""
                    className="user-table-sort-icon"
                    loading="lazy"
                    src="/images/arrow-up-down.svg"
                  />
                </div>
                <div className="table-block table-block-medium all-whiten-in-dark-mode">
                  <h5 className="table-title">{t('TYPE')}</h5>
                  <img
                    alt=""
                    className="user-table-sort-icon"
                    loading="lazy"
                    src="/images/arrow-up-down.svg"
                  />
                </div>
                <div className="table-block table-block-big all-whiten-in-dark-mode">
                  <h5 className="table-title">{t('TOKENS')}</h5>
                  <img
                    alt=""
                    className="user-table-sort-icon"
                    loading="lazy"
                    src="/images/arrow-up-down.svg"
                  />
                </div>
                <div className="table-block table-block-large all-whiten-in-dark-mode">
                  <h5 className="table-title">{t('URL')}</h5>
                  <img
                    alt=""
                    className="user-table-sort-icon hidden"
                    loading="lazy"
                    src="/images/arrow-up-down.svg"
                  />
                </div>
                <div className="table-block table-block-smallest">
                  <h5 className="table-title"> </h5>
                </div>
              </div>
              <div className="w-layout-hflex table-body-row greyish-bg-in-darkmode">
                <div className="table-block table-block-very-big">
                  <a
                    className="table-text-link underline-on-hover w-inline-block"
                    href="https://www.w3.org/WAI/ER/tests/pdf/dummy.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="table-text">
                      {t('Project Execution Map')}
                    </div>
                  </a>
                </div>
                <div className="table-block table-block-medium">
                  <div className="table-text">{t('PDF')}</div>
                </div>
                <div className="table-block table-block-big">
                  <div className="table-text">
                    278558
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-large">
                  <div className="table-text">
                    <em>https://www.w3.org/WAI/ER/- tests/pdf/dummy.pdf</em>
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-small">
                  <div className="user-row-switcher-embed w-embed">
                    <label className="user-switcher">
                      <input defaultChecked="" type="checkbox" />
                      <span className="user-switcher-slider round"></span>
                    </label>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `
    .af-view .user-switcher {
        position: relative;
        display: inline-block;
        width: 33px;
        height: 15px;
        margin-bottom: 0px!important;
    }
    /* Hide default HTML checkbox */
    .af-view .user-switcher input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* The user-switcher-slider */
    .af-view .user-switcher-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .af-view .user-switcher-slider:before {
        position: absolute;
        content: \"\";
        height: 20px;
        width: 20px;
        left: -7px;
        bottom: -3px;
        background-color: #656565;
        -webkit-transition: .1s;
        transition: .1s;
    }
    .af-view .user-switcher input:not(:checked) + .user-switcher-slider img.right-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider img.left-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before{
        background-color: #2467eb;
    }
    .af-view .user-switcher-slider img{
        filter: brightness(0) invert(1) !important;
        width: 16px;
        height: 21px;
        position: absolute;
    }
    .af-view .user-switcher-slider img.left-img{
        left: 3px;
        bottom: 0px;
    }
    .af-view .user-switcher-slider img.right-img{
        right: 0px;
        bottom: -1px;
        width:16px;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider {
        background-color: rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:focus + .user-switcher-slider {
        box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded user-switcher-sliders */
    .af-view .user-switcher-slider.round {
        border-radius: 15px;
    }
    .af-view .user-switcher-slider.round:before {
        border-radius: 50%;
    }
 `,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex table-body-row greyish-bg-in-darkmode">
                <div className="table-block table-block-very-big">
                  <a
                    className="table-text-link underline-on-hover w-inline-block"
                    href="https://bitcoin.org/bitcoin.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="table-text">{t('Bitcoin')}</div>
                  </a>
                </div>
                <div className="table-block table-block-medium">
                  <div className="table-text">PDF</div>
                </div>
                <div className="table-block table-block-big">
                  <div className="table-text">
                    2932
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-large">
                  <div className="table-text">
                    <em>https://bitcoin.org/bitcoin.pdf</em>
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-small">
                  <div className="user-row-switcher-embed w-embed">
                    <label className="user-switcher">
                      <input defaultChecked="" type="checkbox" />
                      <span className="user-switcher-slider round"></span>
                    </label>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `
    .af-view .user-switcher {
        position: relative;
        display: inline-block;
        width: 33px;
        height: 15px;
        margin-bottom: 0px!important;
    }
    /* Hide default HTML checkbox */
    .af-view .user-switcher input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* The user-switcher-slider */
    .af-view .user-switcher-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .af-view .user-switcher-slider:before {
        position: absolute;
        content: \"\";
        height: 20px;
        width: 20px;
        left: -7px;
        bottom: -3px;
        background-color: #656565;
        -webkit-transition: .1s;
        transition: .1s;
    }
    .af-view .user-switcher input:not(:checked) + .user-switcher-slider img.right-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider img.left-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before{
        background-color: #2467eb;
    }
    .af-view .user-switcher-slider img{
        filter: brightness(0) invert(1) !important;
        width: 16px;
        height: 21px;
        position: absolute;
    }
    .af-view .user-switcher-slider img.left-img{
        left: 3px;
        bottom: 0px;
    }
    .af-view .user-switcher-slider img.right-img{
        right: 0px;
        bottom: -1px;
        width:16px;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider {
        background-color: rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:focus + .user-switcher-slider {
        box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded user-switcher-sliders */
    .af-view .user-switcher-slider.round {
        border-radius: 15px;
    }
    .af-view .user-switcher-slider.round:before {
        border-radius: 50%;
    }
 `,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex table-body-row greyish-bg-in-darkmode">
                <div className="table-block table-block-very-big">
                  <a
                    className="table-text-link underline-on-hover w-inline-block"
                    href="https://ibleducation.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="table-text">IBL Education</div>
                  </a>
                </div>
                <div className="table-block table-block-medium">
                  <div className="table-text">{t('URL')}</div>
                </div>
                <div className="table-block table-block-big">
                  <div className="table-text">
                    8699
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-large">
                  <div className="table-text">
                    <em>https://ibleducation.com/</em>
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-small">
                  <div className="user-row-switcher-embed w-embed">
                    <label className="user-switcher">
                      <input defaultChecked="" type="checkbox" />
                      <span className="user-switcher-slider round"></span>
                    </label>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `
    .af-view .user-switcher {
        position: relative;
        display: inline-block;
        width: 33px;
        height: 15px;
        margin-bottom: 0px!important;
    }
    /* Hide default HTML checkbox */
    .af-view .user-switcher input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* The user-switcher-slider */
    .af-view .user-switcher-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .af-view .user-switcher-slider:before {
        position: absolute;
        content: \"\";
        height: 20px;
        width: 20px;
        left: -7px;
        bottom: -3px;
        background-color: #656565;
        -webkit-transition: .1s;
        transition: .1s;
    }
    .af-view .user-switcher input:not(:checked) + .user-switcher-slider img.right-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider img.left-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before{
        background-color: #2467eb;
    }
    .af-view .user-switcher-slider img{
        filter: brightness(0) invert(1) !important;
        width: 16px;
        height: 21px;
        position: absolute;
    }
    .af-view .user-switcher-slider img.left-img{
        left: 3px;
        bottom: 0px;
    }
    .af-view .user-switcher-slider img.right-img{
        right: 0px;
        bottom: -1px;
        width:16px;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider {
        background-color: rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:focus + .user-switcher-slider {
        box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded user-switcher-sliders */
    .af-view .user-switcher-slider.round {
        border-radius: 15px;
    }
    .af-view .user-switcher-slider.round:before {
        border-radius: 50%;
    }
 `,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex table-body-row greyish-bg-in-darkmode">
                <div className="table-block table-block-very-big">
                  <a
                    className="table-text-link underline-on-hover w-inline-block"
                    href="https://www.africau.edu/images/default/sample.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="table-text">{t('Sample')}</div>
                  </a>
                </div>
                <div className="table-block table-block-medium">
                  <div className="table-text">PDF</div>
                </div>
                <div className="table-block table-block-big">
                  <div className="table-text">
                    278558
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-large">
                  <div className="table-text">
                    <em>https://www.africau.edu/- images/default/sample.pdf</em>
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-small">
                  <div className="user-row-switcher-embed w-embed">
                    <label className="user-switcher">
                      <input defaultChecked="" type="checkbox" />
                      <span className="user-switcher-slider round"></span>
                    </label>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `
    .af-view .user-switcher {
        position: relative;
        display: inline-block;
        width: 33px;
        height: 15px;
        margin-bottom: 0px!important;
    }
    /* Hide default HTML checkbox */
    .af-view .user-switcher input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* The user-switcher-slider */
    .af-view .user-switcher-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .af-view .user-switcher-slider:before {
        position: absolute;
        content: \"\";
        height: 20px;
        width: 20px;
        left: -7px;
        bottom: -3px;
        background-color: #656565;
        -webkit-transition: .1s;
        transition: .1s;
    }
    .af-view .user-switcher input:not(:checked) + .user-switcher-slider img.right-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider img.left-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before{
        background-color: #2467eb;
    }
    .af-view .user-switcher-slider img{
        filter: brightness(0) invert(1) !important;
        width: 16px;
        height: 21px;
        position: absolute;
    }
    .af-view .user-switcher-slider img.left-img{
        left: 3px;
        bottom: 0px;
    }
    .af-view .user-switcher-slider img.right-img{
        right: 0px;
        bottom: -1px;
        width:16px;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider {
        background-color: rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:focus + .user-switcher-slider {
        box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded user-switcher-sliders */
    .af-view .user-switcher-slider.round {
        border-radius: 15px;
    }
    .af-view .user-switcher-slider.round:before {
        border-radius: 50%;
    }
 `,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex table-body-row greyish-bg-in-darkmode">
                <div className="table-block table-block-very-big">
                  <a
                    className="table-text-link underline-on-hover w-inline-block"
                    href="https://www.w3.org/WAI/ER/tests/pdf/dummy.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="table-text">
                      {t('Project Execution Map')}
                    </div>
                  </a>
                </div>
                <div className="table-block table-block-medium">
                  <div className="table-text">{t('PDF')}</div>
                </div>
                <div className="table-block table-block-big">
                  <div className="table-text">
                    278558
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-large">
                  <div className="table-text">
                    <em>https://www.w3.org/WAI/ER/- tests/pdf/dummy.pdf</em>
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-small">
                  <div className="user-row-switcher-embed w-embed">
                    <label className="user-switcher">
                      <input defaultChecked="" type="checkbox" />
                      <span className="user-switcher-slider round"></span>
                    </label>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `
    .af-view .user-switcher {
        position: relative;
        display: inline-block;
        width: 33px;
        height: 15px;
        margin-bottom: 0px!important;
    }
    /* Hide default HTML checkbox */
    .af-view .user-switcher input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* The user-switcher-slider */
    .af-view .user-switcher-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .af-view .user-switcher-slider:before {
        position: absolute;
        content: \"\";
        height: 20px;
        width: 20px;
        left: -7px;
        bottom: -3px;
        background-color: #656565;
        -webkit-transition: .1s;
        transition: .1s;
    }
    .af-view .user-switcher input:not(:checked) + .user-switcher-slider img.right-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider img.left-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before{
        background-color: #2467eb;
    }
    .af-view .user-switcher-slider img{
        filter: brightness(0) invert(1) !important;
        width: 16px;
        height: 21px;
        position: absolute;
    }
    .af-view .user-switcher-slider img.left-img{
        left: 3px;
        bottom: 0px;
    }
    .af-view .user-switcher-slider img.right-img{
        right: 0px;
        bottom: -1px;
        width:16px;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider {
        background-color: rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:focus + .user-switcher-slider {
        box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded user-switcher-sliders */
    .af-view .user-switcher-slider.round {
        border-radius: 15px;
    }
    .af-view .user-switcher-slider.round:before {
        border-radius: 50%;
    }
 `,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex table-body-row greyish-bg-in-darkmode">
                <div className="table-block table-block-very-big">
                  <a
                    className="table-text-link underline-on-hover w-inline-block"
                    href="https://ibleducation.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="table-text">IBL Education</div>
                  </a>
                </div>
                <div className="table-block table-block-medium">
                  <div className="table-text">{t('URL')}</div>
                </div>
                <div className="table-block table-block-big">
                  <div className="table-text">
                    8699
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-large">
                  <div className="table-text">
                    <em>https://ibleducation.com/</em>
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-small">
                  <div className="user-row-switcher-embed w-embed">
                    <label className="user-switcher">
                      <input defaultChecked="" type="checkbox" />
                      <span className="user-switcher-slider round"></span>
                    </label>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `
    .af-view .user-switcher {
        position: relative;
        display: inline-block;
        width: 33px;
        height: 15px;
        margin-bottom: 0px!important;
    }
    /* Hide default HTML checkbox */
    .af-view .user-switcher input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* The user-switcher-slider */
    .af-view .user-switcher-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .af-view .user-switcher-slider:before {
        position: absolute;
        content: \"\";
        height: 20px;
        width: 20px;
        left: -7px;
        bottom: -3px;
        background-color: #656565;
        -webkit-transition: .1s;
        transition: .1s;
    }
    .af-view .user-switcher input:not(:checked) + .user-switcher-slider img.right-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider img.left-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before{
        background-color: #2467eb;
    }
    .af-view .user-switcher-slider img{
        filter: brightness(0) invert(1) !important;
        width: 16px;
        height: 21px;
        position: absolute;
    }
    .af-view .user-switcher-slider img.left-img{
        left: 3px;
        bottom: 0px;
    }
    .af-view .user-switcher-slider img.right-img{
        right: 0px;
        bottom: -1px;
        width:16px;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider {
        background-color: rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:focus + .user-switcher-slider {
        box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded user-switcher-sliders */
    .af-view .user-switcher-slider.round {
        border-radius: 15px;
    }
    .af-view .user-switcher-slider.round:before {
        border-radius: 50%;
    }
 `,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex table-body-row greyish-bg-in-darkmode">
                <div className="table-block table-block-very-big">
                  <a
                    className="table-text-link underline-on-hover w-inline-block"
                    href="https://bitcoin.org/bitcoin.pdf"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <div className="table-text">{t('Bitcoin')}</div>
                  </a>
                </div>
                <div className="table-block table-block-medium">
                  <div className="table-text">{t('PDF')}</div>
                </div>
                <div className="table-block table-block-big">
                  <div className="table-text">
                    2932
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-large">
                  <div className="table-text">
                    <em>https://bitcoin.org/bitcoin.pdf</em>
                    <br />
                  </div>
                </div>
                <div className="table-block table-block-small">
                  <div className="user-row-switcher-embed w-embed">
                    <label className="user-switcher">
                      <input defaultChecked="" type="checkbox" />
                      <span className="user-switcher-slider round"></span>
                    </label>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: `
    .af-view .user-switcher {
        position: relative;
        display: inline-block;
        width: 33px;
        height: 15px;
        margin-bottom: 0px!important;
    }
    /* Hide default HTML checkbox */
    .af-view .user-switcher input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* The user-switcher-slider */
    .af-view .user-switcher-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .af-view .user-switcher-slider:before {
        position: absolute;
        content: \"\";
        height: 20px;
        width: 20px;
        left: -7px;
        bottom: -3px;
        background-color: #656565;
        -webkit-transition: .1s;
        transition: .1s;
    }
    .af-view .user-switcher input:not(:checked) + .user-switcher-slider img.right-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider img.left-img{
        display: none;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before{
        background-color: #2467eb;
    }
    .af-view .user-switcher-slider img{
        filter: brightness(0) invert(1) !important;
        width: 16px;
        height: 21px;
        position: absolute;
    }
    .af-view .user-switcher-slider img.left-img{
        left: 3px;
        bottom: 0px;
    }
    .af-view .user-switcher-slider img.right-img{
        right: 0px;
        bottom: -1px;
        width:16px;
    }
    .af-view .user-switcher input:checked + .user-switcher-slider {
        background-color: rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:focus + .user-switcher-slider {
        box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
    }
    .af-view .user-switcher input:checked + .user-switcher-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded user-switcher-sliders */
    .af-view .user-switcher-slider.round {
        border-radius: 15px;
    }
    .af-view .user-switcher-slider.round:before {
        border-radius: 50%;
    }
 `,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-layout-hflex users-settings-pagination-block hidden">
              <div className="w-layout-hflex users-settings-pagination-container all-whiten-in-dark-mode">
                <div className="w-layout-hflex table-pagination-btn table-pagination-btn-inactive greyish-bg-in-darkmode">
                  <img
                    alt=""
                    className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                    loading="lazy"
                    src="/images/chevron-first.svg"
                  />
                </div>
                <div className="w-layout-hflex table-pagination-btn table-pagination-btn-inactive greyish-bg-in-darkmode">
                  <img
                    alt=""
                    className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                    loading="lazy"
                    src="/images/chevron-left-2.svg"
                  />
                </div>
                <div className="w-layout-hflex table-pagination-btn table-pagination-btn-active">
                  <h5 className="table-pagination-page-number primary-color-in-dark-mode">
                    1
                  </h5>
                </div>
                <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                  <h5 className="table-pagination-page-number">2</h5>
                </div>
                <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                  <h5 className="table-pagination-page-number">3</h5>
                </div>
                <div className="w-layout-hflex table-pagination-btn table-pagination-btn-no-hover">
                  <img
                    alt=""
                    className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                    loading="lazy"
                    src="/images/more-horizontal-3.svg"
                  />
                </div>
                <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                  <h5 className="table-pagination-page-number">20</h5>
                </div>
                <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                  <img
                    alt=""
                    className="table-pagination-btn-icon"
                    loading="lazy"
                    src="/images/chevron-right.svg"
                  />
                </div>
                <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                  <img
                    alt=""
                    className="table-pagination-btn-icon"
                    loading="lazy"
                    src="/images/chevron-last-1.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Datasetlistmodalbox;
