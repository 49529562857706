import React from 'react';

export const Error = (message) => {
  const supportEmail =
    process.env.REACT_APP_IBL_DEFAULT_SUPPORT_EMAIL ||
    'support@iblai.zendesk.com';
  return (
    <div
      className="notification-box-container template error"
      style={{ display: 'flex' }}
    >
      <div className="w-layout-hflex notification-box lighter-grey-bg-dark-mode">
        <div className="w-layout-hflex notification-icon-block error">
          <img
            alt=""
            className="notification-icon"
            loading="lazy"
            src="/images/x.svg"
          />
        </div>
        <div className="notification-msg whiten-in-dark-mode">
          <div>
            <span>Sorry about that!</span>
            <span style={{ marginLeft: '5px' }}>{message?.toString()}</span>
          </div>
          <div>
            Please try again or{' '}
            <a href={`mailto:${supportEmail}`}>contact us</a>.
          </div>
        </div>
      </div>
    </div>
  );
};
