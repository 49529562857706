export default function useChatHistoryPagination(data) {
  const count = data?.count;

  let page = 1;
  let numPages = 1;
  let limit = data?.results?.length;
  let offset = 0;

  if (data?.next) {
    const url = new URL(data.next);
    limit = parseInt(url.searchParams.get('limit')) || limit;
    const nextOffset = parseInt(url.searchParams.get('offset')) || 0;
    offset = nextOffset - limit;
  } else if (data?.previous) {
    const url = new URL(data.previous);
    limit = parseInt(url.searchParams.get('limit')) || limit;
    const previousOffset = parseInt(url.searchParams.get('offset')) || 0;
    offset = previousOffset + limit;
  }

  page = Math.floor(offset / limit) + 1;

  if (count && limit) {
    numPages = Math.ceil(count / limit);
  }

  const getOffsetForPage = (pageNumber) => {
    return (pageNumber - 1) * limit;
  };

  return { page, numPages, limit, getOffsetForPage };
}
