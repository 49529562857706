import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../../lib/redux/mentors/slice';
import { chatActions } from '../../../lib/redux/chat/slice';
import { useTriggers } from '../../navigation';
import useNewChat from '../../chat-room/useNewChat';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

export default function useActiveMentor(mentor) {
  const mentors = useSelector((state) => state.mentors);
  const auth = useSelector((state) => state.auth.data);
  const chat = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const {
    _handleUpdateModalStatus,
    _handleUpdateMultipleModalStatus,
    handleOpenEditMentorModal,
  } = useTriggers();
  const { handleNewChatBtnClick } = useNewChat();
  const navigate = useNavigate();

  const active =
    mentor.slug === mentors?.mentor?.slug ||
    mentor.name === mentors?.mentor?.name;

  const handleOpenMentorSettings = () => {
    dispatch(mentorActions.editMentorUpdated(mentor));
    dispatch(mentorActions.editMentorActiveTab('settings'));

    _handleUpdateMultipleModalStatus([
      { name: 'featured-mentor-info-modal', status: false },
      { name: 'edit-mentor-modal', status: true },
    ]);
  };

  const handleSelectMentor = () => {
    if (chat?.generating || chat?.streaming) {
      toast.custom('Your chat is streaming, please wait.');
      return;
    }
    if (mentors?.mentor?.name !== mentor?.name) {
      dispatch(mentorActions.currentMentorUpdated(mentor));
      dispatch(mentorActions.userSelectedMentorUpdated(true));
      navigate(
        `/platform/${auth.tenant.key}/${mentor.slug}${window.location.search}`
      );
      handleNewChatBtnClick();
    }
    _handleUpdateMultipleModalStatus([
      { name: 'featured-mentor-info-modal', status: false },
      { name: 'featured-mentors-modals', status: false },
    ]);
  };

  const handleFeaturedMentorHover = () => {
    $('.ai-expert-desc-block').on('mouseover', function () {
      if ($(this).find('.featured-mentor-tooltip-text').text() === '') {
        return;
      }
      const topPosition = $(this)
        .find('.ai-expert-desc-container')
        .offset().top;
      const width = $(this).width();
      $(this)
        .find('.featured-mentor-tooltip')
        .css({
          //position: "fixed",
          top: `${topPosition}px`,
          display: 'flex',
          width: `${width}px`,
        });
    });

    $('.ai-expert-desc-block').on('mouseout', function () {
      $(this).find('.featured-mentor-tooltip').css({ display: 'none' });
    });
  };

  const handleCloseFeaturedMentorInfoModal = () => {
    _handleUpdateModalStatus('featured-mentor-info-modal', false);
  };

  const handleMentorBoxClick = () => {
    dispatch(mentorActions.mentorToViewUpdated(mentor));
    _handleUpdateModalStatus('featured-mentor-info-modal', true);
  };

  return {
    active,
    handleMentorBoxClick,
    handleCloseFeaturedMentorInfoModal,
    handleSelectMentor,
    handleFeaturedMentorHover,
    handleOpenMentorSettings,
  };
}
