import React from 'react';

export const Success = (message) => {
  return (
    <div className="notification-box-container template success">
      <div className="w-layout-hflex notification-box lighter-grey-bg-dark-mode">
        <div className="w-layout-hflex notification-icon-block success">
          <img
            alt=""
            className="notification-icon"
            loading="lazy"
            src="/images/check.svg"
          />
        </div>
        <div className="notification-msg whiten-in-dark-mode">{message}</div>
      </div>
    </div>
  );
};
