import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import useIframeEvents from '../hooks/iframe/useIframeEvents';
import { notifyParentOnLoad } from '../utils/auth';

export default function Main() {
  const { handleIframeMessage } = useIframeEvents();

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);
    notifyParentOnLoad();
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  return <Outlet />;
}
