import Defaultsuggestedprompttext from '../Defaultsuggestedprompttext/Defaultsuggestedprompttext';
import React from 'react';
import './Aidefaultreponsebox.css';
import { useSelector } from 'react-redux';
import { DEFAULT_INITIAL_MESSAGE } from '../../utils/shared';

const Aidefaultreponsebox = (props) => {
  const mentors = useSelector((state) => state.mentors);
  let defaultMessage = mentors?.mentor?.settings?.initial_message;
  if (!defaultMessage || defaultMessage.trim() === '') {
    defaultMessage = `I'm your ${mentors?.mentor?.name}. Not sure where to start? You can try:`;
  }

  return (
    <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
      <div className="ai-gif-container">
        <img
          alt=""
          className="image-3"
          src={
            mentors?.mentor?.settings?.profile_image ??
            '/images/ai-academy-p-500.png'
          }
          loading="lazy"
          style={{ borderRadius: '50%' }}
        />
      </div>
      <div className="ai-response-text-container">
        <h5 className="ai-default-msg whiten-in-dark-mode">{defaultMessage}</h5>
        <Defaultsuggestedprompttext />
      </div>
    </div>
  );
};

export default Aidefaultreponsebox;
