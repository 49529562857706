export const usePageNumberPagination = (data) => {
  const count = data?.count;

  let page = 1;
  let numPages = 1;
  let page_size = data?.results?.length;

  if (data?.next) {
    const url = new URL(data.next);
    const pageParam = url.searchParams.get('page');
    page = parseInt(pageParam, 10) - 1;
    page_size = parseInt(url.searchParams.get('page_size')) || page_size;
    numPages = Math.ceil(count / page_size);
  } else if (data?.previous) {
    const url = new URL(data.previous);
    const pageParam = url.searchParams.get('page');
    page = parseInt(pageParam, 10) + 1;
    page_size = parseInt(url.searchParams.get('page_size')) || page_size;
    numPages = Math.ceil(count / page_size);
  }

  return { page, numPages };
};
