import { inIframe } from '@iblai/ibl-web-react-common';
import { authGuard } from './auth';
import { BASE_API_URL } from '../api';

const urlParams = new URLSearchParams(window.location.search);

const getMentorPublicSettings = async (platformKey, mentorId) => {
  let username = 'anonymous';
  const userDataStr = localStorage.getItem('userData');
  if (userDataStr) {
    const userData = JSON.parse(userDataStr);
    username = userData?.user_nicename;
  }
  const url = `${BASE_API_URL}/api/ai-mentor/orgs/${platformKey}/users/${username}/mentors/${mentorId}/public-settings/`;
  const response = await fetch(url);
  return await response.json();
};

export const loadPlatformData = (appData, setAppData) => {
  const embed = urlParams.get('embed') === 'true';
  return async ({ request, params }) => {
    const mentorSettings = await getMentorPublicSettings(
      params.platform,
      params.mentor
    );
    let requireLogin = true;
    if (inIframe() && embed) {
      requireLogin = !Boolean(mentorSettings?.allow_anonymous);
    }
    await authGuard(false, requireLogin, appData, setAppData)();

    return {
      mentorSettings,
    };
  };
};
