import { createGlobalStyle } from 'styled-components';
import { GLOBAL_COLORS } from '@iblai/ibl-web-react-common';
import { mentorIsIframe } from './shared';

export const GlobalStyles = createGlobalStyle`
  html {
    --grey: ${GLOBAL_COLORS.grey};
    --font: ${GLOBAL_COLORS.font};
    --accent: ${GLOBAL_COLORS.primary};
    --accenthover: ${GLOBAL_COLORS.secondary};
    --accent-2: ${GLOBAL_COLORS.tertiary};
    --gainsboro: ${GLOBAL_COLORS.grey_secondary};
    --white-smoke: ${GLOBAL_COLORS.white_smoke};
    --white: white;
    --black: black;
    --3: white;
  }
`;

export const EmbedCustomStyles =
  new URLSearchParams(window.location.search).get('mode') === 'anonymous' &&
  mentorIsIframe()
    ? createGlobalStyle`
      
    .user-prompt-text{
        border-radius: 8px 8px 0 8px!important;
        min-height: 20px!important;
        padding: 6px 9px!important;
        font-size: 13px!important;
        line-height: 20px!important;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .1)!important;
    }
    .user-prompt-text-block{
        max-width: 280px!important;
    }
    .learn-more-container{
        grid-row-gap: 3px!important;
    }
    .ai-response-container{
        border-radius: 15px 15px 15px 0px!important;
        padding-top: 7px!important;
        padding-bottom: 5px!important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .1)!important;
    }
    .ai-gif-container{
        padding-top: 5px!important;
    }
    .learn-more-block{
        border-top: .3px solid #a7a7a736!important;
        margin-top: 5px!important;
        padding: 5px 20px 0!important;
    }
    .response-action-icon{
        width: 14px!important;
    }
    .response-action-label{
        font-size: 12px!important;
    }
    .logo-container{
        grid-column-gap: 10px!important;
    }
    .text-block-35.suggested-prompt-label{
        font-size: 12px!important;
    }
    .user-prompt-input-field.black-bg-dark-mode.whiten-border-in-dark-mode.whiten-in-dark-mode{
        font-size: 14px!important;
    }
    .logo-container img{
        width: 30px!important;
    }
    .default-suggestion-prompts-block, .ai-default-msg{
        font-size: 14px!important;
    }
    .ai-response-text-container{
        grid-row-gap: 5px!important;
    }
`
    : () => {
        return <></>;
      };
