import { useSelector } from 'react-redux';
import useApi from '../base/useApi';
import { toast } from 'react-hot-toast';
import { Error } from '../../../modules/alerts';

export default function useDocumentTrain() {
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();

  const handleDocumentTrain = async (
    data,
    notify = true,
    isForm = false
  ) => {
    const endpoint = `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth.user.user_nicename}/documents/train/`;
    const response = await api.post(endpoint,data, isForm);
    if (response?.data) {
      if (notify) {
        toast.success('document has been queued!');
      }
      return response?.data;
    } else {
      if (notify) {
        toast.custom(Error(response?.error));
      }
    }
  };

  return { handleDocumentTrain };
}
