import React from 'react';
import './Suggestedpromptbox.css';
import { useMenus, useTriggers } from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import useSuggestedPrompt from '../../hooks/chat-room/useSuggestedPrompt';
import { useTranslation } from 'react-i18next';
import usePreviewMode from '../../hooks/iframe/usePreviewMode';

const Suggestedpromptbox = ({ isShare, handleRedirectToMentor }) => {
  const { handleSuggestedPromptBtnClick } = useTriggers();
  const menus = useMenus();
  const navigation = useSelector((state) => state.navigation.data);
  const mentors = useSelector((state) => state.mentors);
  const embedPreview = usePreviewMode();
  const { handleSuggestedPromptSelection } = useSuggestedPrompt();
  const { t } = useTranslation();

  const handleClick = () => {
    if (embedPreview) {
      return;
    }

    if (isShare) {
      handleRedirectToMentor();
      return;
    }
    handleSuggestedPromptBtnClick();
  };

  return (
    <div className="w-dropdown" data-delay={0} data-hover="false">
      <div
        className="mentor-suggestion-prompt-dropdown-toggle w-dropdown-toggle"
        style={{ cursor: embedPreview ? 'not-allowed !important' : 'pointer' }}
        title={embedPreview ? 'Chat is disabled in preview mode' : ''}
        onClick={handleClick}
        prop-events-value-onclick="handleSuggestedPromptBtnClick"
      >
        <div className="w-layout-hflex mentor-footer-btn-block light-black-bg-dark-mode all-whiten-in-dark-mode">
          <div className="w-layout-hflex mentor-footer-btn prompt-selector-dropdown-toggle mentor-footer-action-btn-focused">
            <img
              alt=""
              className="mentor-footer-action-icon"
              loading="lazy"
              src="/images/terminal.svg"
            />
          </div>
          <div className="text-block-35 suggested-prompt-label whiten-in-dark-mode">
            <strong className="bold-text-2 whiten-in-dark-mode">
              {t('Suggested Prompts')}
            </strong>
          </div>
        </div>
      </div>
      {navigation?.menus?.['suggestedprompt'] && (
        <nav
          ref={menus?.refs?.suggestedprompt}
          className="dropdown-list-2 w-dropdown-list w--open"
        >
          <div className="w-layout-vflex copilot-suggestion-prompt-dropdown-block black-bg-dark-mode all-whiten-in-dark-mode">
            {mentors?.mentor?.suggested_prompts?.map((item, i) => (
              <div
                key={`${i}-suggested-prompt`}
                onClick={() => handleSuggestedPromptSelection(item?.prompt)}
                className="copilot-suggestion-prompt-element"
              >
                {item?.prompt}
              </div>
            ))}

            {mentors?.mentor?.suggested_prompts?.length === 0 && (
              <div>
                <i>'{t('No suggested prompts')}</i>
              </div>
            )}
          </div>
        </nav>
      )}
    </div>
  );
};

export default Suggestedpromptbox;
