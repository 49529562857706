import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';

export default function useSelectMentorLLM(llm) {
  const auth = useSelector((state) => state.auth.data);
  const editMentor = useSelector((state) => state.mentors.edit);
  const mentors = useSelector((state) => state.mentors);
  const dispatch = useDispatch();

  const api = useApi();

  const handleUpdateLocalMentors = (data) => {
    const updatedList = mentors.mentors?.results?.map((item) => {
      if (item.name === data.name) {
        return data;
      }
      return item;
    });

    dispatch(
      mentorActions.mentorsUpdated({ ...mentors.mentors, results: updatedList })
    );
  };

  const handleLLMSelected = async (chat = false) => {
    const mentor = chat ? mentors?.mentor : editMentor?.mentor;
    const mentorUniqueId = mentor.unique_id;

    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${mentorUniqueId}/settings/`;
    const payload = {
      llm_provider: llm.name,
      llm_name: llm.model,
    };

    const response = await api.put(url, payload);
    if (response?.data) {
      const updatedMentor = {
        ...mentor,
        llm_name: response?.data?.llm_name,
        llm_provider: response?.data?.llm_provider,
        settings: response?.data,
      };
      console.log(chat, 'chat value now');
      if (chat !== true) {
        dispatch(mentorActions.editMentorUpdated(updatedMentor));
        if (mentor.unique_id === mentors?.mentor?.unique_id) {
          dispatch(mentorActions.currentMentorUpdated(updatedMentor));
        }
      }
      handleUpdateLocalMentors(updatedMentor);
    }
  };

  const llmIsSelected = () => {
    return (
      editMentor?.mentor?.llm_name === llm.model ||
      editMentor?.mentor?.settings?.llm_name === llm.model
    );
  };

  return { handleLLMSelected, llmIsSelected };
}
