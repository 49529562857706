import React from 'react';
import './Helpmodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import { useTranslation } from 'react-i18next';

const Helpmodalbox = (props) => {
  const { handleHelpMenuCloseClick } = useTriggers();
  const { t } = useTranslation();
  return (
    <div className="modal help-modal">
      <div className="w-layout-vflex modal-container help-modal-container help-container">
        <div className="w-layout-hflex modal-header help-modal-header">
          <h3 className="modal-header-title">Help</h3>
          <div
            prop-events-value-onclick="handleModalCloseClick"
            prop-events-names="onClick"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            className="modal-close-wrapper"
            onClick={handleHelpMenuCloseClick}
          >
            <img
              src="/images/close_1close.png"
              loading="lazy"
              alt="Close Button Image"
            />
          </div>
        </div>
        <div className="w-layout-vflex modal-body help-modal-body">
          <div className="w-layout-vflex flex-block-13">
            <div>{t('Popular Resources')}</div>
          </div>
          <a
            href="https://www.youtube.com/watch?v=zuwkFAe3PiA&amp;list=PLW0-4yErlU3XoFnvVcqm2qsxls2lXNDkm&amp;index=4"
            target="_blank"
            className="link-block-4 w-inline-block"
          >
            <div className="w-layout-hflex help-element">
              <div className="help-el-icon-container">
                <img
                  src="/images/youtube.svg"
                  loading="lazy"
                  alt=""
                  className="help-el-icon-img"
                />
              </div>
              <div className="help-el-description">
                {t('Create A Study Buddy Mentor')}
              </div>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=Y6oDSjXQiRs&amp;list=PLW0-4yErlU3XoFnvVcqm2qsxls2lXNDkm&amp;index=3&amp;t=51s"
            target="_blank"
            className="link-block-4 w-inline-block"
          >
            <div className="w-layout-hflex help-element">
              <div className="help-el-icon-container">
                <img
                  src="/images/youtube.svg"
                  loading="lazy"
                  alt=""
                  className="help-el-icon-img"
                />
              </div>
              <div className="help-el-description">
                {t('Teaching Assistant Mentor')}
              </div>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=1pbOhUCwcoc&amp;list=PLW0-4yErlU3XoFnvVcqm2qsxls2lXNDkm&amp;index=2"
            target="_blank"
            className="link-block-4 w-inline-block"
          >
            <div className="w-layout-hflex help-element">
              <div className="help-el-icon-container">
                <img
                  src="/images/youtube.svg"
                  loading="lazy"
                  alt=""
                  className="help-el-icon-img"
                />
              </div>
              <div className="help-el-description">{t('Lesson Planner')}</div>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=4wO0Ql-rTtk&amp;list=PLW0-4yErlU3XoFnvVcqm2qsxls2lXNDkm&amp;index=8"
            target="_blank"
            className="link-block-4 w-inline-block"
          >
            <div className="w-layout-hflex help-element">
              <div className="help-el-icon-container">
                <img
                  src="/images/youtube.svg"
                  loading="lazy"
                  alt=""
                  className="help-el-icon-img"
                />
              </div>
              <div className="help-el-description">
                {t('Training From a Website')}
              </div>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=TNnWx4GGliM&amp;list=PLW0-4yErlU3XoFnvVcqm2qsxls2lXNDkm&amp;index=13&amp;t=6s"
            target="_blank"
            className="link-block-4 w-inline-block"
          >
            <div className="w-layout-hflex help-element">
              <div className="help-el-icon-container">
                <img
                  src="/images/youtube.svg"
                  loading="lazy"
                  alt=""
                  className="help-el-icon-img"
                />
              </div>
              <div className="help-el-description">
                {t('Multi-language Support')}
              </div>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=hlVbw06XWFE&amp;list=PLW0-4yErlU3XoFnvVcqm2qsxls2lXNDkm&amp;index=12&amp;t=5s"
            target="_blank"
            className="link-block-4 w-inline-block"
          >
            <div className="w-layout-hflex help-element">
              <div className="help-el-icon-container">
                <img
                  src="/images/youtube.svg"
                  loading="lazy"
                  alt=""
                  className="help-el-icon-img"
                />
              </div>
              <div className="help-el-description">{t('Customer Support')}</div>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=xPcQq2B5AbI&amp;list=PLW0-4yErlU3XoFnvVcqm2qsxls2lXNDkm&amp;index=11"
            target="_blank"
            className="link-block-4 w-inline-block"
          >
            <div className="w-layout-hflex help-element">
              <div className="help-el-icon-container">
                <img
                  src="/images/youtube.svg"
                  loading="lazy"
                  alt=""
                  className="help-el-icon-img"
                />
              </div>
              <div className="help-el-description">
                {t('Exploring Course Subjects and Topics')}
              </div>
            </div>
          </a>
          {/*<div className="help-search-input-block w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              method="get"
              className="help-search-form"
              data-wf-page-id="650305782bc61751e5765214"
              data-wf-element-id="3f69cca6-0506-dab3-0ea6-dad18e6db4e0"
            >
              <input
                className="help-search-input w-input"
                autoComplete="on"
                maxLength="256"
                name="name-2"
                data-name="Name 2"
                placeholder="Search Help"
                type="text"
                id="name-2"
              />
              <img
                src="/images/search-svgrepo-com.svg"
                loading="lazy"
                alt=""
                className="image-25"
              />
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>*/}
          <div className="help-seperator-line"></div>
          <a
            href="mailto:support@ibleducation.com?subject=Report%20a%20problem"
            className="link-block-3 w-inline-block"
          >
            <aside className="w-layout-hflex help-element help-element-report-problem">
              <div className="help-el-icon-container help-el-report-problem">
                <img
                  src="/images/info-message-svgrepo-com-blueish.svg"
                  loading="lazy"
                  alt=""
                  className="help-el-icon-img"
                />
              </div>
              <div className="help-el-description help-report-problem">
                {t('Report a problem')}
              </div>
            </aside>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Helpmodalbox;
