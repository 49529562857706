import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Home from './home';
import { useDispatch, useSelector } from 'react-redux';
import useSetCurrentMentor from '../../hooks/api/mentors/useSetCurrentMentor';
import PageLoader from '../loader/page-loader';
import { Toaster } from 'react-hot-toast';
import Creatementormodalbox from '../../components/Creatementormodalbox/Creatementormodalbox';
import Editmentormodalbox from '../../components/Editmentormodalbox/Editmentormodalbox';
import useAnonymousMode from '../../hooks/iframe/useAnonymousMode';
import useAnonymousMentor from '../../hooks/iframe/useAnonymousMentor';
import useGuidedSuggestedPrompts from '../../hooks/chat-room/useGuidedSuggestedPrompts';
import { useUserTimeTracking } from '@iblai/ibl-web-react-common';
import useUserAuth from '../../hooks/user/useUserAuth';

export default function HomeWrapper() {
  const auth = useSelector((state) => state.auth.data);
  const sessionId = useSelector((state) => state.chat.sessionId);
  useUserTimeTracking();

  const mentors = useSelector((state) => state.mentors);
  const anonymous = mentors.mentor?.settings?.allow_anonymous;

  const [startMentorSetup, setStartMentorSetup] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSetCurrentMentor(startMentorSetup);
  const anonymousMentor = useAnonymousMentor();

  const { modals } = useSelector((state) => state.navigation.data);
  const { loadGuidedPrompts } = useGuidedSuggestedPrompts();
  const { setupUserAuth } = useUserAuth();
  const location = useLocation();

  useEffect(() => {
    if (!anonymous && auth.user && auth.tenant) {
      setStartMentorSetup(true);
    }
  }, [auth]);

  useEffect(() => {
    if (sessionId) {
      loadGuidedPrompts();
    }
  }, [sessionId]);

  useEffect(() => {
    setupUserAuth();
  }, []);

  return (
    <>
      {(location?.pathname.includes('/share/chat') ||
        ((auth?.user || anonymous) && mentors?.mentor && auth?.tenant)) && (
        <Home />
      )}{' '}
      {(loading || anonymousMentor?.loading) && <PageLoader />}
      {modals?.['mentor-creation-modal'] && !mentors?.mentor && (
        <Creatementormodalbox />
      )}
      {modals?.['edit-mentor-modal'] && mentors?.mentors?.count === 0 && (
        <Editmentormodalbox />
      )}
      <Toaster
        toastOptions={{
          className: 'site-toaster-container',
        }}
        containerStyle={{
          zIndex: 2147483647,
        }}
      />
    </>
  );
}
