import { ErrorBoundary } from '../errors/pages/error-boundary';
import IndexView from '../views/IndexView';
import { authGuard } from '../utils/route-guard/auth';
import NoMentors from '../modules/no-mentors';
import ShareChat from '../modules/share/chat';
import { SsoLogin } from '@iblai/ibl-web-react-common';
import Platform from '../modules/platform';
import Checkout from '../modules/checkout';
import Coupons from '../modules/coupons';
import ChromeExtSSOLogin from '../modules/chrome-extension/chrome-ext-sso-login';
import ChromeExtSSOLoginComplete from '../modules/chrome-extension/chrome-ext-sso-login-complete';
import Main from './Main';
import PlatformLayout from '../modules/platform/platform-layout';
import { loadPlatformData } from '../utils/route-guard/loaders';

export const routes = (appData, setAppData) => [
  {
    path: '/',
    errorElement: <ErrorBoundary />,
    element: <Main />,
    children: [
      {
        index: true,
        element: <IndexView />,
        loader: authGuard(false, true, appData, setAppData),
      },
      {
        path: 'no-mentors',
        element: <NoMentors />,
        loader: authGuard(false, false, appData, setAppData),
      },
      {
        path: 'index.html',
        element: <IndexView />,
        loader: authGuard(false, true, appData, setAppData),
      },

      {
        path: 'share/chat/:sessionId',
        element: <ShareChat />,
        loader: authGuard(false, false, appData, setAppData),
      },
      {
        path: 'sso-login',
        element: <SsoLogin />,
        loader: authGuard(false, false, appData, setAppData),
      },
      {
        path: 'platform',
        loader: loadPlatformData(appData, setAppData),
        element: <PlatformLayout />,
        children: [
          {
            path: ':platform/:mentor',
            element: <Platform />,
          },
          {
            path: ':platform/checkout/:sku',
            element: <Checkout />,
          },
          {
            path: ':platform/coupons/:code',
            element: <Coupons />,
          },
        ],
      },
      {
        path: 'chrome-ext-sso-login',
        element: <ChromeExtSSOLogin />,
        loader: authGuard(false, false, appData, setAppData),
      },
      {
        path: 'chrome-ext-login-complete',
        element: <ChromeExtSSOLoginComplete />,
        loader: authGuard(false, false, appData, setAppData),
      },
    ],
  },
];
