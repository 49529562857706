import Mobilesettingsmenudropdownbox from '../Mobilesettingsmenudropdownbox/Mobilesettingsmenudropdownbox';
import React, { useEffect } from 'react';
import './Helpmenudropdownbox.css';
import { useTriggers } from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Helpmenudropdownbox = ({
  leftSideBarBottomPositionned = false,
  className = '',
  style = {},
  ...rest
}) => {
  const { handleHelpMenuClick, handleFaqMenuClick } = useTriggers();
  const tenantCustomization = useSelector(
    (state) => state.navigation.tenantCustomization
  );
  const { t } = useTranslation();
  const DEFAULT_SUPPORT_EMAIL = process.env.REACT_APP_IBL_DEFAULT_SUPPORT_EMAIL;

  const BOTTOM_POSITION =
    55 /*DEFAULT POSITION FOR FIRST ELEMENT*/ +
    ((tenantCustomization.enabled && !tenantCustomization?.metadata?.show_help
      ? 0
      : 1) +
      (tenantCustomization.enabled && !tenantCustomization?.metadata?.show_faq
        ? 0
        : 1)) *
      50;

  return (
    <nav
      style={{ bottom: BOTTOM_POSITION, ...style }}
      className={`header-help-dropdown-container help-dropdown ${leftSideBarBottomPositionned ? 'bottom-side-positionned' : ''} w-dropdown-list ${className}`}
      {...rest}
    >
      <div className="w-layout-vflex user-menu-dropdown-block black-bg-dark-mode all-whiten-in-dark-mode whiten-border-in-dark-mode help-user-menu-dropdown-block">
        {/*<a
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
          href="#"
          prop-events-value-onclick="handleUpdateBtnClick"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="/images/note-svgrepo-com.svg"
          />
          <div className="user-menu-label">Updates</div>
        </a>*/}
        {tenantCustomization.enabled &&
        !tenantCustomization?.metadata?.show_help ? (
          <></>
        ) : (
          <a
            className="user-menu-link greyish-bg-in-darkmode w-inline-block"
            data-w-id="173f0889-c967-79aa-41d4-5e029a3d5141"
            onClick={(event) => {
              event.preventDefault();
              handleHelpMenuClick(event);
            }}
            href="#"
            prop-events-value-onclick="handleHelpBtnClick"
          >
            <img
              alt=""
              className="user-menu-icon"
              loading="lazy"
              src="/images/info-message-svgrepo-com.svg"
            />
            <div className="user-menu-label">{t('Help')}</div>
          </a>
        )}
        {tenantCustomization.enabled &&
        !tenantCustomization?.metadata?.show_faq ? (
          <></>
        ) : (
          <a
            className="user-menu-link greyish-bg-in-darkmode w-inline-block"
            href="#"
            prop-events-value-onclick="handleFAQBtnClick"
            onClick={(event) => {
              event.preventDefault();
              handleFaqMenuClick(event);
            }}
          >
            <img
              alt=""
              className="user-menu-icon"
              loading="lazy"
              src="/images/question-answer-svgrepo-com.svg"
            />
            <div className="user-menu-label">FAQ</div>
          </a>
        )}
        <a
          href={`mailto:${tenantCustomization.enabled && tenantCustomization?.metadata?.support_email ? tenantCustomization?.metadata?.support_email : DEFAULT_SUPPORT_EMAIL}`}
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
        >
          <img
            src="/images/privacy-tips-svgrepo-com.svg"
            loading="lazy"
            alt="Support Button Image"
            className="user-menu-icon"
          />
          <div className="user-menu-label">{t('Support')}</div>
        </a>
      </div>
    </nav>
  );
};

export default Helpmenudropdownbox;
