import useTools from '../../../hooks/tools/useTools';
import ToolItem from './tool-item';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useSelector } from 'react-redux';

export default function MentorTools() {
  const { loading, tools } = useTools();

  return (
    <div className="w-layout-vflex edit-mentor-tools-tab">
      <div className="secret-key-form-block edit-mentor-settings-form-block w-form">
        <form
          id="email-form-2"
          name="email-form-2"
          data-name="Email Form 2"
          method="get"
          className="manage-account-form secret-key-form edit-mentor-settings-form"
          data-wf-page-id="66953e194f60e4f88496390e"
          data-wf-element-id="b0ebc614-db16-3423-a3cc-fb83bc6a69ec"
        >
          <div className="w-layout-hflex flex-block-81">
            <div className="w-layout-vflex tools-block less-width">
              <div className="tools-block-seperator"></div>
              {tools?.map((item) => (
                <ToolItem item={item} key={item.id} />
              ))}
            </div>
          </div>

          {loading && (
            <div>
              <IBLSpinLoader size={20} />
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
