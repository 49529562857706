import { copyTextToClipBoard } from '../../../utils/shared';
import { toast } from 'react-hot-toast';
import useSuggestedPrompt from '../../chat-room/useSuggestedPrompt';
import { useTriggers } from '../../navigation';
import { useDispatch, useSelector } from 'react-redux';
import { promptActions } from '../../../lib/redux/prompts/slice';
import { mentorActions } from '../../../lib/redux/mentors/slice';

export default function usePromptItem(prompt) {
  const dispatch = useDispatch();
  const editMentor = useSelector((state) => state.mentors.edit.mentor);
  const { handleSuggestedPromptSelection, deleteSuggestedPrompt } =
    useSuggestedPrompt();
  const { _handleUpdateMultipleModalStatus, handleOpenPromptDetailModal } =
    useTriggers();
  const handleCopyPrompt = () => {
    copyTextToClipBoard(prompt?.description);
    toast.success('Copied!');
  };
  const handleShowPromptDetail = () => {
    handleOpenPromptDetailModal();
    dispatch(promptActions.selectedPromptUpdated(prompt));
  };

  const handleDeleteSuggestedPrompt = async () => {
    const response = await deleteSuggestedPrompt(prompt.id);
    if (response?.data) {
      dispatch(
        mentorActions.editMentorUpdated({
          ...editMentor,
          updated: !Boolean(editMentor.updated),
        })
      );
      toast.success('Prompt has been deleted!');
    }
  };

  const handleTriggerPrompt = () => {
    handleSuggestedPromptSelection(prompt.description);
    setTimeout(() => {
      _handleUpdateMultipleModalStatus([
        { name: 'prompt-gallery-modal', status: false },
        { name: 'prompt-gallery-element-view-modal', status: false },
        { name: 'mentor-list-modal', status: false },
        { name: 'edit-mentor-modal', status: false },
        { name: 'featured-mentors-modals', status: false },
      ]);
    }, 300);
  };

  return {
    handleCopyPrompt,
    handleTriggerPrompt,
    handleShowPromptDetail,
    handleDeleteSuggestedPrompt,
  };
}
