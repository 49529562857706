import React from "react";
import "./Promptdesignmodalbox.css";
import { useTranslation } from "react-i18next";


const Promptdesignmodalbox = props => {
    const {t} = useTranslation();
    return (
      <div className="modal llm-prompt-design-modal">
        <div className="w-layout-vflex modal-container prompt-gallery-container">
          <div className="w-layout-hflex modal-header">
            <h3 className="modal-header-title">{t('Prompt Design')}</h3>
            <a className="default-btn prompt-add-btn w-button" href="#">
              {t('+ Add New Prompt')}
            </a>
            <div
              className="modal-close-wrapper"
              data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
              prop-events-value-onclick="handleModalCloseClick"
            >
              <img alt="" loading="lazy" src="/images/close_1close.png" />
            </div>
          </div>
          <div className="w-layout-vflex modal-body all-whiten-in-dark-mode">
            <div className="prompts-container-2 prompt-design-container">
              <div className="w-layout-vflex prompt-design-left-container">
                <div className="w-layout-hflex top-header left-top-header">
                  <div className="w-layout-hflex top-header-container left-top-header-container">
                    <div className="w-layout-hflex top-header-return-btn">
                      <img
                        alt=""
                        className="image-43"
                        loading="lazy"
                        src="/images/arrow-left-1.svg"
                      />
                    </div>
                    <h4 className="heading-16">{t('LLama 2')}</h4>
                  </div>
                  <div className="w-layout-hflex top-header-btn-block">
                    <div className="w-layout-hflex top-header-btn top-header-active-btn">
                      <img
                        alt=""
                        className="top-header-btn-icon"
                        loading="lazy"
                        src="/images/file-text-1.svg"
                      />
                      <h5 className="top-header-btn-title">{t('FREEFORM')}</h5>
                    </div>
                    <div className="w-layout-hflex top-header-btn">
                      <img
                        alt=""
                        className="top-header-btn-icon"
                        loading="lazy"
                        src="/images/layout-panel-top.svg"
                      />
                      <h5 className="top-header-btn-title">{t('STRUCTURED')}</h5>
                    </div>
                  </div>
                </div>
                <div className="w-layout-vflex prompt-design-body">
                  <div className="w-layout-vflex prompt-container-2">
                    <div className="w-layout-vflex flex-block-62">
                      <div className="w-layout-hflex prompt-container-header">
                        <div className="w-layout-hflex prompt-container-header-icon">
                          <img
                            alt=""
                            className="image-44"
                            loading="lazy"
                            src="/images/chevron-down-4.svg"
                          />
                        </div>
                        <h5 className="heading-17">{t('Prompt')}</h5>
                      </div>
                      <div className="w-layout-hflex prompt-container-body">
                        <div className="form-block-9 w-form">
                          <form
                            className="form-4"
                            data-name="Email Form 4"
                            data-wf-element-id="294ba157-9a70-2bd2-f4ce-b831e5325f25"
                            data-wf-page-id="650305782bc61751e5765214"
                            id="email-form-4"
                            method="get"
                            name="email-form-4"
                          >
                            <textarea
                              className="textarea-3 w-input"
                              data-name="Field 3"
                              defaultValue={''}
                              id="field-3"
                              maxLength={5000}
                              name="field-3"
                              placeholder="Example Text"
                            ></textarea>
                            <div className="w-layout-hflex prompt-form-mic-container">
                              <img
                                alt=""
                                className="image-47"
                                loading="lazy"
                                src="/images/microphone-1.png"
                              />
                              <div className="tooltip-container">
                                <div className="tooltip-2 prompt-mic-tooltip">
                                  {t('Use microphone')}
                                </div>
                              </div>
                            </div>
                          </form>
                          <div className="w-form-done">
                            <div>
                              {t('Thank you! Your submission has been received!')}
                            </div>
                          </div>
                          <div className="w-form-fail">
                            <div>
                              {t('Oops! Something went wrong while submitting the form.')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-vflex add-new-prompt-content-2">
                      <div className="w-layout-hflex prompt-section-header-block-2">
                        <div className="w-layout-hflex flex-block-63">
                          <div className="w-layout-hflex prompt-container-header-icon">
                            <img
                              alt=""
                              className="image-44"
                              loading="lazy"
                              src="/images/chevron-down-4.svg"
                            />
                          </div>
                          <h5 className="heading-20">{t('Response')}</h5>
                        </div>
                        <div className="w-layout-hflex switcher-container add-new-prompt-switcher-container">
                          <div className="switch-html-embed w-embed">
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round">
                                <img
                                  alt=""
                                  className="left-img"
                                  src="/custom-assets/img/minus.svg"
                                />
                                <img
                                  alt=""
                                  className="right-img"
                                  src="/custom-assets/img/check.svg"
                                />
                              </span>
                            </label>
                            <style
                              dangerouslySetInnerHTML={{
                                __html: `
    .af-view .switch {
        position: relative;
        display: inline-block;
        width: 46px;
        height: 20px;
        margin-bottom: 0px!important;
    }
    /* Hide default HTML checkbox */
    .af-view .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* The slider */
    .af-view .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .2s;
        transition: .2s;
    }
    .af-view .slider:before {
        position: absolute;
        content: \"\";
        height: 24px;
        width: 24px;
        left: 0px;
        bottom: -2px;
        background-color: #000000;
        -webkit-transition: .1s;
        transition: .1s;
    }
    .af-view input:not(:checked) + .slider img.right-img{
        display: none;
    }
    .af-view input:checked + .slider img.left-img{
        display: none;
    }
    .af-view input:checked + .slider:before{
        background-color: #2467eb;
    }
    .af-view .slider img{
        filter: brightness(0) invert(1) !important;
        width: 16px;
        height: 21px;
        position: absolute;
    }
    .af-view .slider img.left-img{
        left: 4px;
    		bottom: -1px;
    }
    .af-view .slider img.right-img{
        right: 0px;
    		bottom: -1px;
        width:16px;
    }
    .af-view input:checked + .slider {
        background-color: rgba(36, 103, 235, 0.6);
    }
    .af-view input:focus + .slider {
        box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
    }
    .af-view input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
    /* Rounded sliders */
    .af-view .slider.round {
        border-radius: 15px;
    }
    .af-view .slider.round:before {
        border-radius: 50%;
    }
    .af-view .input-checked-label{
    		color:rgb(36, 103, 235)!important;
    }
 `,
                              }}
                            />
                          </div>
                          <h5 className="switch-label-txt whiten-in-dark-mode prompt-markdown-label">
                            {t('Markdown')}
                          </h5>
                        </div>
                      </div>
                      <div className="w-layout-hflex add-new-prompt-result-container-2">
                        <div className="text-block-45">
                          {t('This model will generate a response after you click Submit')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-vflex prompt-design-right-container">
                <div className="w-layout-hflex top-header right-top-header">
                  <div className="w-layout-hflex top-header-btn-block right-side-top-header-btn-block">
                    <div className="w-layout-hflex top-header-btn">
                      <img
                        alt=""
                        className="top-header-btn-icon"
                        loading="lazy"
                        src="/images/arrow-down-to-line-1.svg"
                      />
                      <h5 className="top-header-btn-title">{t('SAVE')}</h5>
                    </div>
                    <div className="w-layout-hflex top-header-btn">
                      <img
                        alt=""
                        className="top-header-btn-icon"
                        loading="lazy"
                        src="/images/code-1.svg"
                      />
                      <h5 className="top-header-btn-title">{t('VIEW CODE')}</h5>
                    </div>
                  </div>
                </div>
                <div className="w-layout-hflex prompt-design-body prompt-settings-body">
                  <div className="w-layout-vflex prompt-settings-container">
                    <div className="w-layout-hflex feedback-container">
                      <img
                        alt=""
                        className="image-45"
                        loading="lazy"
                        src="/images/info-circle-svgrepo-com.svg"
                      />
                      <div className="prompt-feedback-rich-text w-richtext">
                        <p>
                          {t('We want your')} <a href="http://www.ibl.ai">{t('feedback')}</a>
                        </p>
                      </div>
                    </div>
                    <div className="w-layout-vflex prompt-settings-block">
                      <div className="prompt-settings-form-block w-form">
                        <form
                          className="prompt-settings-form"
                          data-name="Email Form 5"
                          data-wf-element-id="294ba157-9a70-2bd2-f4ce-b831e5325f56"
                          data-wf-page-id="650305782bc61751e5765214"
                          id="email-form-5"
                          method="get"
                          name="email-form-5"
                        >
                          <div className="w-layout-hflex prompt-model-dropdown">
                            <select
                              className="select-field-4 w-select"
                              data-name="Field 4"
                              id="field-4"
                              name="field-4"
                            >
                              <option value="llama2">llama2@001</option>
                              <option value="palm">palm@001</option>
                              <option value="claude">claude@001</option>
                              <option value="openai">openai@001</option>
                            </select>
                            <h6 className="heading-18">{t('Model')}</h6>
                          </div>
                          <div className="w-layout-vflex prompt-settings-row">
                            <div className="w-layout-hflex prompt-settings-label-block">
                              <div className="prompt-settings-label-text">
                                {t('Temperature')}
                              </div>
                              <img
                                alt=""
                                className="prompt-settings-label-icon"
                                loading="lazy"
                                src="/images/question-circle-svgrepo-com.svg"
                              />
                            </div>
                            <div className="w-layout-hflex prompt-settings-slider-input-block">
                              <div className="w-layout-hflex prompt-settings-slider-block">
                                <div className="prompt-settings-slider-number">
                                  0
                                </div>
                                <div className="prompt-settings-slider-embed w-embed">
                                  <input
                                    className="range-slider"
                                    defaultValue="0.2"
                                    max={1}
                                    min={0}
                                    step="0.1"
                                    type="range"
                                  />
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html: `
	.af-view .range-slider{
  	width:100%;
  }
 `,
                                    }}
                                  />
                                </div>
                                <div className="prompt-settings-slider-number">
                                  1
                                </div>
                              </div>
                              <div className="w-layout-hflex prompt-settings-input-block">
                                <input
                                  className="prompt-settings-input-field w-input"
                                  data-name="Field 5"
                                  id="field-5"
                                  maxLength={256}
                                  name="field-5"
                                  placeholder="0.2"
                                  required=""
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-layout-vflex prompt-settings-row">
                            <div className="w-layout-hflex prompt-settings-label-block">
                              <div className="prompt-settings-label-text">
                                {t('Token Limit')}
                              </div>
                              <img
                                alt=""
                                className="prompt-settings-label-icon"
                                loading="lazy"
                                src="/images/question-circle-svgrepo-com.svg"
                              />
                            </div>
                            <div className="w-layout-hflex prompt-settings-slider-input-block">
                              <div className="w-layout-hflex prompt-settings-slider-block">
                                <div className="prompt-settings-slider-number">
                                  1
                                </div>
                                <div className="prompt-settings-slider-embed w-embed">
                                  <input
                                    className="range-slider"
                                    defaultValue={768}
                                    max={1024}
                                    min={1}
                                    type="range"
                                  />
                                </div>
                                <div className="prompt-settings-slider-number">
                                  1024
                                </div>
                              </div>
                              <div className="w-layout-hflex prompt-settings-input-block">
                                <input
                                  className="prompt-settings-input-field w-input"
                                  data-name="Field 5"
                                  id="field-5"
                                  maxLength={256}
                                  name="field-5"
                                  placeholder={768}
                                  required=""
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-layout-vflex prompt-settings-row">
                            <div className="w-layout-hflex prompt-settings-label-block">
                              <div className="prompt-settings-label-text">
                                Top-K
                              </div>
                              <img
                                alt=""
                                className="prompt-settings-label-icon"
                                loading="lazy"
                                src="/images/question-circle-svgrepo-com.svg"
                              />
                            </div>
                            <div className="w-layout-hflex prompt-settings-slider-input-block">
                              <div className="w-layout-hflex prompt-settings-slider-block">
                                <div className="prompt-settings-slider-number">
                                  1
                                </div>
                                <div className="prompt-settings-slider-embed w-embed">
                                  <input
                                    className="range-slider"
                                    defaultValue={40}
                                    max={40}
                                    min={1}
                                    type="range"
                                  />
                                </div>
                                <div className="prompt-settings-slider-number">
                                  40
                                </div>
                              </div>
                              <div className="w-layout-hflex prompt-settings-input-block">
                                <input
                                  className="prompt-settings-input-field w-input"
                                  data-name="Field 5"
                                  id="field-5"
                                  maxLength={256}
                                  name="field-5"
                                  placeholder={40}
                                  required=""
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-layout-vflex prompt-settings-row">
                            <div className="w-layout-hflex prompt-settings-label-block">
                              <div className="prompt-settings-label-text">
                                Top-P
                              </div>
                              <img
                                alt=""
                                className="prompt-settings-label-icon"
                                loading="lazy"
                                src="/images/question-circle-svgrepo-com.svg"
                              />
                            </div>
                            <div className="w-layout-hflex prompt-settings-slider-input-block">
                              <div className="w-layout-hflex prompt-settings-slider-block">
                                <div className="prompt-settings-slider-number">
                                  0
                                </div>
                                <div className="prompt-settings-slider-embed w-embed">
                                  <input
                                    className="range-slider"
                                    defaultValue="0.8"
                                    max={1}
                                    min={0}
                                    step="0.1"
                                    type="range"
                                  />
                                </div>
                                <div className="prompt-settings-slider-number">
                                  1
                                </div>
                              </div>
                              <div className="w-layout-hflex prompt-settings-input-block">
                                <input
                                  className="prompt-settings-input-field w-input"
                                  data-name="Field 5"
                                  id="field-5"
                                  maxLength={256}
                                  name="field-5"
                                  placeholder="0.8"
                                  required=""
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-layout-hflex prompt-model-dropdown">
                            <select
                              className="select-field-4 w-select"
                              data-name="Field 4"
                              id="field-4"
                              name="field-4"
                            >
                              <option value="block-few">{t('Block few')}</option>
                              <option value="None">{t('None')}</option>
                            </select>
                            <h6 className="heading-18">
                              {t('Safety filter threshold')}
                            </h6>
                          </div>
                          <div className="w-layout-hflex flex-block-64">
                            <a className="button-primary w-button" href="#">
                              {t('Submit')}
                            </a>
                            <a
                              className="button-primary no-background w-button"
                              href="#"
                            >
                              {t('Reset Parameters')}
                            </a>
                          </div>
                          <a
                            className="prompt-report-responses-link w-inline-block"
                            href="#"
                          >
                            <div className="w-layout-hflex flex-block-65">
                              <img
                                alt=""
                                className="image-46"
                                loading="lazy"
                                src="/images/flag.svg"
                              />
                              <h5 className="heading-19">
                                {t('REPORT INAPPROPRIATE RESPONSES')}
                              </h5>
                            </div>
                          </a>
                        </form>
                        <div className="w-form-done">
                          <div>
                            {t('Thank you! Your submission has been received!')}
                          </div>
                        </div>
                        <div className="w-form-fail">
                          <div>
                            {t('Oops! Something went wrong while submitting the form.')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );}

export default Promptdesignmodalbox;