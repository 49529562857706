import { mentorIsIframe } from './shared';
import {
  api,
  getUserDataFromCookie,
  inIframe,
  isJSON,
} from '@iblai/ibl-web-react-common';

export function getPlatformKey(url) {
  const match = url.match(/\/platform\/([^\/]+)\//);
  return match ? match[1] : null;
}

export const notifyParentOnLoad = () => {
  window.parent.postMessage(
    JSON.stringify({ loaded: true, auth: { ...localStorage } }),
    '*'
  );
};

export const notifyParentOnReady = () => {
  window.parent.postMessage(JSON.stringify({ ready: true }), '*');
};

export const notifyParentToSetup = () => {
  window.parent.postMessage(JSON.stringify({ setup: true }), '*');
};

export const notifyParentOnTokenExpiry = () => {
  window.parent.postMessage(
    JSON.stringify({ authExpired: true, auth: { ...localStorage } }),
    '*'
  );
};

export const redirectToAuth = (
  enforceLogin = false,
  redirectToUrl = null,
  platform = null
) => {
  localStorage.clear();

  if (inIframe()) {
    notifyParentOnReady();
    return;
  }

  const redirectPath = `${window.location.pathname}${window.location.search}`;
  localStorage.setItem('redirect-path', redirectPath);
  platform = getPlatformKey(redirectPath);

  let authRedirectUrl = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?app=mentor`;
  if (enforceLogin && !mentorIsIframe()) {
    authRedirectUrl += '&enforce-login=1';
  }

  if (!Boolean(redirectToUrl)) {
    redirectToUrl = `${window.location.origin}`;
  }

  authRedirectUrl += `&redirect-to=${redirectToUrl}`;

  if (platform) {
    authRedirectUrl += `&tenant=${platform}`;
  }

  window.location.href = authRedirectUrl;
};

export const initiateAppContextData = () => {
  return {
    ...localStorage,
    userData: isJSON(localStorage['userData'])
      ? JSON.parse(localStorage['userData'])
      : null,
    ibl_user_public_metadata: isJSON(
      localStorage.getItem('ibl_user_public_metadata')
    )
      ? JSON.parse(localStorage.getItem('ibl_user_public_metadata'))
      : null,
    userMetaData: {},
    userInContext: '',
  };
};

export const axdTokenIsExpired = () => {
  const expiry = localStorage.getItem('axd_token_expires');
  if (expiry === null) {
    return true;
  }
  const expiryDate = new Date(expiry);
  const now = new Date();
  return now >= expiryDate;
};

export const completeAuthFromToken = (token) => {
  const tokenData = api.iblutils.parseJwt(token || '');
  localStorage.setItem('token', token);
  const axd_token = tokenData.data.axd_token;
  const axd_token_expires = tokenData.data.axd_token_expires;

  localStorage.setItem('axd_token', axd_token);
  localStorage.setItem('axd_token_expires', axd_token_expires);
  localStorage.setItem('userData', JSON.stringify(tokenData.data.user));
  localStorage.setItem('ibl_axd_url', tokenData.data.ibl_axd_url);
  localStorage.setItem('ibl_web_url', tokenData.data.ibl_web_url);
  localStorage.setItem('ibl_edx_url', tokenData.data.ibl_edx_url);
  localStorage.setItem('ibl_search_url', tokenData.data.ibl_search_url);
  localStorage.setItem(
    'current_tenant',
    JSON.stringify(tokenData.data.current_tenant)
  );
  localStorage.setItem('tenants', JSON.stringify(tokenData.data.tenants));
  localStorage.setItem('tenant', tokenData.data.current_tenant.key);
};
