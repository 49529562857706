import usePlatformRequest from '../../hooks/platform/usePlatformRequest';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFreeTrial from '../../hooks/freetrial/useFreeTrial';
import useUserAuth from '../../hooks/user/useUserAuth';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Error } from '../alerts';
import BasePlatform from '../platform/base';

export default function Coupons() {
  const pathParams = useParams();
  const { ready } = usePlatformRequest();
  const auth = useSelector((state) => state.auth.data);
  const { handleSubmitUpgrade } = useFreeTrial();
  const { setupUserAuth } = useUserAuth();

  const checkoutWithCoupon = async () => {
    const response = await handleSubmitUpgrade(pathParams.code);
    if (response?.error && response.error.includes('Invalid coupon')) {
      toast.custom(Error('Invalid coupon'));
      setTimeout(() => {
        window.location.assign('/');
      }, 8000);
    }
  };

  useEffect(() => {
    if (ready && auth?.tenant?.key && auth?.user?.user_nicename) {
      checkoutWithCoupon();
    }
  }, [auth]);

  useEffect(() => {
    setupUserAuth();
  }, [ready]);

  return <BasePlatform />;
}
