import React from 'react';
import './Defaultsuggestedprompttext.css';
import { useSelector } from 'react-redux';
import useSuggestedPrompt from '../../hooks/chat-room/useSuggestedPrompt';
import { DEFAULT_SUGGESTED_MESSAGE } from '../../utils/shared';
import usePreviewMode from '../../hooks/iframe/usePreviewMode';

const Defaultsuggestedprompttext = (props) => {
  const mentors = useSelector((state) => state.mentors);
  const embedPreview = usePreviewMode();
  let defaultPrompt = mentors?.mentor?.settings?.suggested_message;
  if (!defaultPrompt || defaultPrompt.trim() === '') {
    const mentorSuggestedPrompts = mentors?.mentor?.settings?.suggested_prompts;
    defaultPrompt = mentorSuggestedPrompts?.length
      ? mentorSuggestedPrompts[0]?.prompt
      : DEFAULT_SUGGESTED_MESSAGE;
  }

  const { handleSuggestedPromptSelection } = useSuggestedPrompt();

  return (
    <div
      className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode"
      map=""
      title={embedPreview ? '' : 'Chat is disabled in preview mode'}
      style={{ cursor: embedPreview ? 'not-allowed' : 'pointer' }}
      onClick={
        embedPreview
          ? () => {}
          : () => handleSuggestedPromptSelection(defaultPrompt)
      }
      map-value="label"
    >
      <div className="default-suggestion-prompt">{defaultPrompt}</div>
    </div>
  );
};

export default Defaultsuggestedprompttext;
