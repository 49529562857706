import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import useUpdateMentorSettings from '../api/mentors/useUpdateMentorSettings';
import useMentorUtils from '../utils/useMentorUtils';
import { userIsOnTrial } from '../../utils/shared';
import { useTriggers } from '../navigation';

export default function useToolItem(tool) {
  const editMentor = useSelector((state) => state.mentors.edit.mentor);
  const [selected, setSelected] = useState(false);
  const { handleUpdateSettings } = useUpdateMentorSettings();
  const { updateLocalMentorSettings } = useMentorUtils();
  const { handleOpenFreeTrialModal } = useTriggers();

  useEffect(() => {
    const currentTools = editMentor?.settings?.mentor_tools ?? [];
    const match = currentTools.find((item) => item.slug === tool.slug);
    if (match) {
      setSelected(true);
    }
  }, []);

  const handleAllowToUseTools = async () => {
    const payload = {
      can_use_tools: true,
    };
    await handleUpdateSettings(editMentor, payload, false);
  };

  const handleToggleTool = async (event) => {
    if (userIsOnTrial()) {
      handleOpenFreeTrialModal();
      return;
    }
    const { checked } = event.target;
    let currentTools = editMentor?.settings?.mentor_tools ?? [];

    if (checked) {
      currentTools = [...currentTools, tool];
    } else {
      currentTools = currentTools.filter((item) => item.slug !== tool.slug);
    }

    const payload = {
      tool_slugs: currentTools.map((item) => item.slug),
    };
    if (!editMentor.can_use_tools) {
      await handleAllowToUseTools();
    }
    const updatedSettings = await handleUpdateSettings(editMentor, payload);
    updateLocalMentorSettings(editMentor, updatedSettings);
    setSelected(checked);
  };

  return { handleToggleTool, selected };
}
