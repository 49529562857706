import {
  api,
  getUserDataFromCookie,
  getCurrentTenantFromCookie,
  getUserName,
  inIframe,
} from '@iblai/ibl-web-react-common';
import { json } from 'react-router-dom';

export const authGuard = (
  requireAdminRole,
  requireLogin,
  appData,
  setAppData
) => {
  const userDataFromCookie = getUserDataFromCookie();
  const currentTenantFromCookie = getCurrentTenantFromCookie();

  return async () => {
    if (!requireLogin) {
      return [];
    } else if (
      (requireLogin && (!appData?.axd_token || !appData?.dm_token)) ||
      !userDataFromCookie ||
      userDataFromCookie?.user_id !== appData.userData?.user_id
    ) {
      if (!inIframe()) {
        console.log(
          'route-guard: user_id from cookie and localstorage are inconsistent ',
          userDataFromCookie,
          appData.userData
        );
        throw json({}, { status: 401 });
      }
    } else {
      const getUserMetadataPromise = new Promise((_resolve, _reject) => {
        if (!inIframe() && !Object.keys(appData.userMetaData).length) {
          api.ibledxusers.getUsersManageMetadata(
            { username: getUserName() },
            function (data) {
              _resolve({
                userMetaData: { ...data },
                userMetaDataLoaded: true,
              });
            },
            (jqXHR, textStatus, errorThrown) => {
              // allow the JS SDK perform to handle in case of 401
              if (jqXHR.status === 401) {
                _resolve({});
              } else {
                _reject({});
              }
            }
          );
        } else {
          _resolve({});
        }
      });
      const getTenantsPromise = new Promise((_resolve, _reject) => {
        const selectedTenant = localStorage.getItem('tenant');
        if (!localStorage.getItem('tenants')) {
          api.ibledxtenants.getUserTenants((tenants) => {
            api.iblutils.saveUserTenantsDataToLocalStorage(
              tenants,
              selectedTenant
            );
            _resolve({
              current_tenant: localStorage.getItem('current_tenant'),
              tenants: localStorage.getItem('tenants'),
            });
          });
        } else {
          let currentTenant = localStorage.getItem('current_tenant');
          let currentTenantKey;
          try {
            currentTenantKey = JSON.parse(currentTenant)?.key;
          } catch (e) {}
          api.iblutils.saveUserTenantsDataToLocalStorage(
            JSON.parse(localStorage.getItem('tenants')),
            selectedTenant
          );
          // Getting the new tenant after setting

          currentTenant = JSON.parse(localStorage.getItem('current_tenant'));
          if (currentTenantKey !== currentTenant.key) {
            _resolve({
              current_tenant: localStorage.getItem('current_tenant'),
              tenants: localStorage.getItem('tenants'),
            });
          } else {
            _resolve({});
          }
        }
      });
      try {
        const [_userMetaDataInfo, _tenantInfo] = await Promise.all([
          getUserMetadataPromise,
          getTenantsPromise,
        ]);

        const currentTenantData = JSON.parse(localStorage.current_tenant);

        if (requireAdminRole && !currentTenantData.is_admin) {
          return json({}, { status: 403 });
        }

        if (
          Object.keys(_userMetaDataInfo)?.length ||
          Object.keys(_tenantInfo)?.length
        ) {
          setAppData({ ...appData, ..._userMetaDataInfo, ..._tenantInfo });
        }

        const currentTenant = JSON.parse(
          localStorage.getItem('current_tenant')
        );
        const userData = JSON.parse(localStorage.getItem('userData'));

        if (
          userData?.user_nicename !== currentTenant?.username ||
          !currentTenantFromCookie ||
          currentTenantFromCookie?.key !== currentTenant?.key
        ) {
          if (!inIframe()) {
            console.log(
              'route-guard: username from userData and current tenant are inconsistent',
              currentTenantFromCookie,
              currentTenant
            );
            throw json({}, { status: 401 });
          }
        }

        return json({}, { status: 200 });
      } catch (error) {
        console.log(
          'route-guard: Exception while getting user metadata and user tenants',
          error
        );
        if (error?.status) {
          throw error;
        } else {
          throw json({}, { status: 401 });
        }
      }
    }
  };
};
