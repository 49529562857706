export default function IBLPagination({
  totalPages,
  currentPage,
  onPageChange,
}) {
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always show first and last page
      pageNumbers.push(1);

      if (currentPage > 3) {
        pageNumbers.push('...');
      }

      // Show pages around current page
      const start = Math.max(2, currentPage - 1);
      const end = Math.min(totalPages - 1, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - 2) {
        pageNumbers.push('...');
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <div className="w-layout-hflex users-settings-pagination-block">
      <div className="w-layout-hflex users-settings-pagination-container all-whiten-in-dark-mode">
        <div
          onClick={() => {
            if (currentPage === 1) return;
            onPageChange(1);
          }}
          className={`w-layout-hflex table-pagination-btn-2 ${currentPage === 1 ? 'table-pagination-btn-inactive' : ''} greyish-bg-in-darkmode`}
        >
          <img
            loading="lazy"
            src="/images/chevron-first.svg"
            alt=""
            className={`table-pagination-btn-icon ${currentPage === 1 ? 'table-pagination-btn-icon-disabled' : ''}`}
          />
        </div>
        <div
          onClick={() => {
            if (currentPage === 1) return;
            onPageChange(currentPage - 1);
          }}
          className={`w-layout-hflex table-pagination-btn-2 ${currentPage === 1 ? 'table-pagination-btn-inactive' : ''} greyish-bg-in-darkmode`}
        >
          <img
            loading="lazy"
            src="/images/chevron-left-2.svg"
            alt=""
            className={`table-pagination-btn-icon ${currentPage === 1 ? 'table-pagination-btn-icon-disabled' : ''}`}
          />
        </div>
        {getPageNumbers().map((number, index) => (
          <div
            key={index}
            onClick={() => {
              if (currentPage === number || number === '...') return;
              onPageChange(number);
            }}
            className={`w-layout-hflex table-pagination-btn-2 page-item ${currentPage === number ? 'table-pagination-btn-active' : ''} ${number === '...' ? 'disabled' : ''}`}
          >
            {number === '...' ? (
              <span className="page-link">...</span>
            ) : (
              <h5 className="table-pagination-page-number primary-color-in-dark-mode">
                {number}
              </h5>
            )}
          </div>
        ))}

        <div
          onClick={() => {
            if (currentPage === totalPages) return;
            onPageChange(currentPage + 1);
          }}
          className={`w-layout-hflex table-pagination-btn-2 greyish-bg-in-darkmode ${currentPage === totalPages ? 'table-pagination-btn-inactive' : ''}`}
        >
          <img
            loading="lazy"
            src="/images/chevron-right.svg"
            alt=""
            className={`table-pagination-btn-icon ${currentPage === totalPages ? 'table-pagination-btn-icon-disabled' : ''}`}
          />
        </div>

        <div
          onClick={() => {
            if (currentPage === totalPages) return;
            onPageChange(totalPages);
          }}
          className={`w-layout-hflex table-pagination-btn-2 greyish-bg-in-darkmode ${currentPage === totalPages ? 'table-pagination-btn-inactive' : ''}`}
        >
          <img
            loading="lazy"
            src="/images/chevron-last-1.svg"
            alt=""
            className={`table-pagination-btn-icon ${currentPage === totalPages ? 'table-pagination-btn-icon-disabled' : ''}`}
          />
        </div>
      </div>
    </div>
  );
}
