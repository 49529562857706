import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { api, getUserName } from '@iblai/ibl-web-react-common';
import { Error } from '../../modules/alerts';

export default function useInviteNewUser() {
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState('');
  const auth = useSelector((state) => state.auth.data);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCreateInvitationSuccess = (data) => {
    setEmail('');
    setSubmitting(false);
    toast.success('Invitation has been successfully submitted');
  };

  const handleCreateInvitationError = (error) => {
    setSubmitting(false);
    toast.custom(Error('There was an issue submitting the invitation'));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      email: email,
      source: getUserName(),
      redirect_to: window.location.origin,
      platform_key: auth?.tenant?.key,
    };
    setSubmitting(true);
    api.ibldmplatform.createCatalogInvitationsPlatform(
      data,
      handleCreateInvitationSuccess,
      handleCreateInvitationError
    );
  };

  return { email, submitting, handleSubmit, handleChange };
}
