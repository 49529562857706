import React from 'react';
import './Paymentmethodmodalbox.css';
import { useTranslation } from 'react-i18next';

const Paymentmethodmodalbox = (props) => {
  const { t } = useTranslation();
  return (
    <div className="modal payment-method-modal">
      <div className="w-layout-vflex modal-container payment-method-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{t('Payment Methods')}</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body payment-method-modal-body">
          <div className="w-layout-grid payment-method-block">
            <div
              className="w-layout-vflex payment-method-container"
              id="w-node-_7742785c-c334-11bc-b54a-fce8d3bead4c-e5765214"
            >
              <div className="w-layout-hflex card-number-block">
                <img
                  alt=""
                  className="image-27"
                  loading="lazy"
                  sizes="100vw"
                  src="/images/visa.png"
                  srcSet="images/visa-p-500.png 500w, images/visa.png 512w"
                />
                <div className="card-number-text">**** 7438</div>
              </div>
              <div className="card-text-info">Expires 11/2025</div>
              <div className="card-text-info">{t('Default')}</div>
            </div>
            <div
              className="w-layout-vflex payment-method-container"
              id="w-node-fcbd4778-f8a0-08d1-d9d3-88a63a7920aa-e5765214"
            >
              <div className="w-layout-hflex card-number-block">
                <img
                  alt=""
                  className="image-27"
                  loading="lazy"
                  sizes="100vw"
                  src="/images/visa.png"
                  srcSet="images/visa-p-500.png 500w, images/visa.png 512w"
                />
                <div className="card-number-text">**** 7438</div>
              </div>
              <div className="card-text-info">Expires 11/2025</div>
              <div className="card-text-info">Fallback</div>
            </div>
            <div
              className="w-layout-vflex payment-method-container"
              id="w-node-_117af3f1-7b88-7102-671f-e0fdeba598be-e5765214"
            >
              <div className="w-layout-hflex card-number-block">
                <img
                  alt=""
                  className="image-27"
                  loading="lazy"
                  sizes="100vw"
                  src="/images/visa.png"
                  srcSet="images/visa-p-500.png 500w, images/visa.png 512w"
                />
                <div className="card-number-text">**** 7438</div>
              </div>
              <div className="card-text-info">Expires 11/2025</div>
              <div className="card-text-info">Fallback</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paymentmethodmodalbox;
