import { authActions } from '../../lib/redux/auth/slice';
import { useDispatch } from 'react-redux';

export default function useUserAuth() {
  const dispatch = useDispatch();

  const setupUserAuth = () => {
    const authData = { ...localStorage };
    const authState = {};
    if (authData?.userData) {
      authState['tenants'] = JSON.parse(authData.tenants);
      authState['tenant'] = JSON.parse(authData.current_tenant);
      authState['user'] = JSON.parse(authData.userData);

      dispatch(authActions.authUpdated(authState));
    }
  };

  return { setupUserAuth };
}
