import React from 'react';
import { useTriggers } from '../../hooks/navigation';
import { useTranslation } from 'react-i18next';

export default function ThemeSwitcher() {
  const { handleThemeSwicherBtnClick } = useTriggers();
  const { t } = useTranslation();
  return (
    <div
      data-w-id="5735f898-0762-546d-3f6b-46c1a250bea6"
      className="w-layout-hflex left-sidebar-bottom-btn theme-switcher-container greyish-bg-in-darkmode has-custom-tooltip"
      onClick={handleThemeSwicherBtnClick}
    >
      <img
        src="/images/moon.svg"
        loading="lazy"
        alt=""
        className="left-sidebar-bottom-btn-icon theme-switcher-icon"
      />
      <img
        src="/images/sun-1.svg"
        loading="lazy"
        alt=""
        className="left-sidebar-bottom-btn-icon theme-switcher-icon light-mode"
      />
      <div className="tooltip tooltip-left-sidebar-bottom-btn theme-switcher">
        {t('Dark/Light Theme Switcher')}
      </div>
      <div className="left-sidebar-bottom-btn-label">{t('Theme Switcher')}</div>
    </div>
  );
}
