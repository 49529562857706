import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { toast } from 'react-hot-toast';
import { chatActions } from '../../lib/redux/chat/slice';
import { DOCUMENTS_EXTENSIONS, UPLOAD_MAX_SIZE } from '../../utils/shared';
import { Error } from '../../modules/alerts';

export default function useDocumentUpload() {
  const [isDone, setIsDone] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.data);
  const chat = useSelector((state) => state.chat);

  const api = useApi();

  const handleSubmitAttachment = async () => {
    const sessionId = chat?.sessionId;
    const url = `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/documents/train/sessions/${sessionId}/`;
    const form = document.getElementById('attachment-form');
    const formData = new FormData(form);

    setUploading(true);
    setIsDone(false);
    const response = await api.post(url, formData, true);
    if (response?.data) {
      setIsDone(true);
    } else {
      toast.custom(Error(response?.error || 'Could not upload file'));
    }
    setUploading(false);
    document.getElementById('attachment-upload').value = '';
  };

  useEffect(() => {
    if (isDone) {
      const newMessages = [...chat.messages];
      newMessages.push({
        type: 'human',
        content: file?.name,
        contentType: 'file',
      });
      dispatch(chatActions.messagesUpdated(newMessages));
    }
  }, [isDone]);

  const handlePromptFileUploadBtnClick = (event) => {
    document.getElementById('attachment-upload')?.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files?.length > 0) {
      if (
        !DOCUMENTS_EXTENSIONS.includes('.' + files[0].name.split('.').pop())
      ) {
        toast.custom(Error("The uploaded file extension isn't accepted!"));
        document.getElementById('attachment-upload').value = '';
        return;
      }
      if (files[0].size > UPLOAD_MAX_SIZE) {
        toast.custom(Error(
          `The uploaded file shouldn't exceed ${UPLOAD_MAX_SIZE / (1024 * 1024)} MB.`
        ));
        document.getElementById('attachment-upload').value = '';
        return;
      }
      setFile(files[0]);
      handleSubmitAttachment();
    }
  };

  return { handleFileChange, uploading, file, handlePromptFileUploadBtnClick };
}
