import { toast } from 'react-hot-toast';
import useAnonymousMode from './useAnonymousMode';
import useApi from '../api/base/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { useEffect, useState } from 'react';
import { authActions } from '../../lib/redux/auth/slice';
import { useParams } from 'react-router-dom';
import { Error } from '../../modules/alerts';

export default function useAnonymousMentor() {
  const anonymous = useAnonymousMode();
  const urlParams = new URLSearchParams(window.location.search);
  const pathParams = useParams();
  const mentor = pathParams?.mentor || urlParams.get('mentor');
  const tenant = pathParams?.platform || urlParams.get('tenant');
  const dispatch = useDispatch();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.auth.data);

  if (anonymous && !mentor) {
    toast.custom(Error('No mentor specified'));
  }

  const loadExtraBodyClasses = () => {
    if (urlParams.get('extra-body-classes')) {
      let extraBodyClasses = urlParams.get('extra-body-classes');
      extraBodyClasses = extraBodyClasses.split(',');
      extraBodyClasses.forEach((className) => {
        document?.body?.classList?.add(className);
      });
    }
  };

  const getMentorPublicSettings = async (platformKey, mentorId) => {
    const url = `/api/ai-mentor/orgs/${platformKey}/users/anonymous/mentors/${mentorId}/public-settings/`;
    setLoading(true);
    const response = await api.get(url);
    return response?.data;
  };

  const loadMentorPublicSettings = async () => {
    const userData = localStorage.getItem('userData');
    const settings = await getMentorPublicSettings(tenant, mentor);

    const mentorData = {
      name: settings.mentor,
      slug: settings.mentor_slug,
      unique_id: settings.mentor_unique_id,
      suggested_prompts: settings?.suggested_prompts,
      settings,
      proactive_prompt: settings?.proactive_prompt,
    };

    setLoading(false);
  };

  useEffect(() => {
    loadExtraBodyClasses();
  }, []);

  useEffect(() => {
    if (!mentor && tenant) {
      loadExtraBodyClasses();
    }
  }, []);

  return { loading, getMentorPublicSettings };
}
