import useApi from '../api/base/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export default function useTools() {
  const auth = useSelector((state) => state.auth.data);
  const editMentor = useSelector((state) => state.mentors.edit.mentor);
  const [tools, setTools] = useState(null);
  const [loading, setLoading] = useState(false);
  const api = useApi();

  const loadTools = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentors/${editMentor.slug}/available-tools`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data) {
      setTools(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!tools && !loading) {
      loadTools();
    }
  }, [tools]);

  return { loading, tools };
}
