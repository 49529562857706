import React from "react";
import "./Billingoverviewmodalbox.css";
import { useTranslation } from "react-i18next";


const Billingoverviewmodalbox = props => {
    const {t} = useTranslation();
    
    return (
<div className="modal billing-overview-modal">
<div className="w-layout-vflex modal-container billing-overview-modal-container">
<div className="w-layout-hflex modal-header">
<h3 className="modal-header-title">{t('Billing Overview')}</h3>
<div className="modal-close-wrapper" data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" prop-events-value-onclick="handleModalCloseClick"><img alt="" loading="lazy" src="/images/close_1close.png"/></div>
</div>
<div className="w-layout-vflex modal-body billing-overview-modal-body">
<h4 className="heading-6">Free Trial</h4>
<div className="w-layout-vflex upgrade-account-block">
<div>{t('You are currently on a free trial of the IBL API. This does not reflect the status of your IBL-GPT account.')}</div>
<a className="default-btn upgrade-account-btn w-button" href="https://checkout.stripe.dev/" rel="noopener noreferrer" target="_blank">{t('Upgrade Your Account')}</a>
</div>
<div className="w-layout-hflex billing-overview-submenu-block">
<div className="w-layout-hflex billing-overview-submenu" data-w-id="502d36e7-9841-5ed5-077b-103cd09297ee">
<div className="billing-overview-submenu-icon-container"><img alt="" className="billing-overview-submenu-icon" loading="lazy" src="/images/credit-card.svg"/></div>
<div className="billing-overview-submenu-desc">
<h5 className="billing-overview-submenu-desc-title">{t('Payment Method')}</h5>
<div className="text-block-20 text-block-21 text-block-22 text-block-23 text-block-24 billing-overview-submenu-desc-label">{t('Add or change payment method')}</div>
</div>
</div>
<div className="w-layout-hflex billing-overview-submenu" data-w-id="504a84e7-e63f-f6f4-7a5d-cd8d5fb123ef">
<div className="billing-overview-submenu-icon-container"><img alt="" className="billing-overview-submenu-icon" loading="lazy" src="/images/file.svg"/></div>
<div className="billing-overview-submenu-desc">
<h5 className="billing-overview-submenu-desc-title">{t('Billing history')}</h5>
<div className="text-block-20 text-block-21 text-block-22 text-block-23 text-block-24 billing-overview-submenu-desc-label">{t('View past and current invoices')}</div>
</div>
</div>
<div className="w-layout-hflex billing-overview-submenu" data-w-id="55bd7758-4e82-5dd3-3a2f-55af8288befd">
<div className="billing-overview-submenu-icon-container"><img alt="" className="billing-overview-submenu-icon" loading="lazy" src="/images/sliders.svg"/></div>
<div className="billing-overview-submenu-desc">
<h5 className="billing-overview-submenu-desc-title">{t('Usage limits')}</h5>
<div className="text-block-20 text-block-21 text-block-22 text-block-23 text-block-24 billing-overview-submenu-desc-label">{t('Set monthly spend limits')}</div>
</div>
</div>
</div>
</div>
</div>
</div>
)}

export default Billingoverviewmodalbox;