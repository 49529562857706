import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import useAnonymousMode from '../iframe/useAnonymousMode';

export default function useSubscriptionStatus() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [app, setApp] = useState(null);
  const auth = useSelector((state) => state.auth.data);
  const api = useApi();
  const anonymous = useAnonymousMode();

  const loadSubscriptionsStatus = async () => {
    const url = `/api/features/apps/`;
    const dmToken = localStorage.getItem('dm_token');
    const response = await api.get(url, { app: 'mentor' }, dmToken);
    if (response?.data) {
      const mentorApp = response?.data?.results?.find(
        (item) =>
          item?.app?.name?.toLowerCase()?.includes('mentor') &&
          item?.platform?.key === auth.tenant.key
      );
      if (mentorApp) {
        setApp(mentorApp);
        if (
          !mentorApp?.has_active_subscription &&
          auth?.tenant?.key !== 'main'
        ) {
          setShow(true);
        }
      } else if (auth?.tenant?.key === 'main') {
        await handleStartFreeTrial();
      }
    }
  };

  const handleStartFreeTrial = async () => {
    const url = `/api/features/apps/update-trial-status/`;
    const payload = {
      app: process.env.REACT_APP_IBL_MENTOR_URL,
      free_trial_started: true,
      platform: auth?.tenant?.key,
    };
    const response = await api.post(
      url,
      payload,
      false,
      localStorage.getItem('dm_token')
    );
    if (response?.data) {
    }
  };

  const handleGotoPaymentUpdate = async () => {
    if (app?.app?.url?.startsWith('mentor.')) {
      if (app?.subscription?.status === 'canceled') {
        handleRenewSubscription();
        return;
      }

      const url = `/api/service/orgs/main/users/${auth?.user?.user_nicename}/stripe/customer-portal/`;
      const response = await api.get(url);
      if (response?.data) {
        window.location.assign(response?.data?.url);
      }
    } else {
      window.location.assign(app?.app?.url);
    }
  };

  const handleRenewSubscription = async () => {
    const url = `/api/service/orgs/main/users/${auth?.user?.user_nicename}/stripe/subscription-renewal/`;
    const payload = {
      checkout_session_uuid: app?.subscription?.identifier,
    };
    const response = await api.post(url, payload);
    if (response?.data) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_IBL_ENABLE_STRIPE === 'true' &&
      !loading &&
      !anonymous
    ) {
      loadSubscriptionsStatus();
    }
  }, []);

  return { show, handleGotoPaymentUpdate };
}
